.action-required {
  .delete-candidate-icon {
    height: 16px;
  }
  .convertation-candidate {
    height: 16px;
  }
  img {
    cursor: pointer;
    padding-right: 8px;
  }
}

.action-required .event-icon__wrapper {
  height: 16px;
}
