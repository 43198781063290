.request-top {
  .contact-manager__container {
    display: none;
  }
}

.contact-manager__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  position: sticky;
  margin: 6px 24px;
  top: 90vh;

  .contact-text {
    font-weight: 500;
  }

  .btn-with-tooltip__container {
    cursor: pointer;
  }

  .disabled-btn-with-tooltip {
    cursor: not-allowed;
  }

  form {
    width: 100%;
  }

  .form-group {
    margin-bottom: 0;
  }

  .main-btn.btn-form {
    width: 40px;
    height: 40px;
    padding: 0;
    border-radius: 50%;
    // width: 182px;
    // height: 41px;
    // padding: 10px 24px;
    margin: unset;
    font-size: 16px;
  }
}

@media (max-width: 992px) {
  .contact-manager__container {
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .request-top {
    .contact-manager__container {
      display: flex;
      width: 100%;
      margin: unset;
    }
  }

  .request-details__container {
    .contact-manager__container {
      display: none;
    }
  }
}
