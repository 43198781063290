.planned-relocation {
  display: flex;
  // flex-wrap: wrap;
  flex-direction: row;
  width: 100%;

  .planned-relocation-date {
    .form-group.date-field {
      display: flex;
      // flex-wrap: wrap;
      align-items: center;
      margin-right: 16px;
    }
    .ant-datepicker-wrapper {
      width: 160px;
    }

    .control-label {
      min-width: 260px;
      padding-left: 0;
      font-weight: 400;
      font-size: 22px;
    }
    label {
      margin-bottom: 0;
    }
  }
}

.planned-relocation:has(.note-container) {
  display: flex;
  flex-direction: column;
  .planned-relocation-notes {
    width: 100%;
  }
}
