.sider-menu {
  width: 230px;
  display: flex;
  flex-direction: column;
  padding-top: 16px;
  .ant-menu-light.ant-menu-root.ant-menu-inline,
  .ant-menu-light.ant-menu-root.ant-menu-vertical {
    border-inline-end: unset;
  }
  .ant-menu-inline .ant-menu-item,
  .ant-menu-inline .ant-menu-submenu-title {
    margin: 0;
    width: 230px;
  }
  .ant-menu .ant-menu-item,
  .ant-menu .ant-menu-submenu-title {
    border-radius: unset;
  }

  .ant-menu-title-content,
  .menu-item {
    font-weight: 500;
  }
  .ant-menu-inline > .ant-menu-item,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
    height: 42px;
    line-height: 42px;
  }
  .ant-menu > .ant-menu-item-selected,
  .ant-menu > .ant-menu-item-active,
  .ant-menu > .ant-menu-submenu-selected .ant-menu-submenu-title {
    background-color: #fdecea;
    color: #eb3d26;
  }
  .ant-menu.ant-menu-sub > .ant-menu-item-selected {
    color: #eb3d26;
    background-color: white;
  }
  .ant-menu > .ant-menu-item-selected > .ant-menu-title-content {
    font-weight: 700;
  }
  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-item:not(.ant-menu-item-selected):active,
  .ant-menu-light:not(.ant-menu-horizontal) .ant-menu-submenu-title:active {
    background-color: rgba(0, 0, 0, 0.06);
  }
  .ant-menu-light.ant-menu-inline .ant-menu-sub.ant-menu-inline {
    background: unset;
  }
  .ant-menu-light .ant-menu-item:not(.ant-menu-item-disabled):focus-visible,
  .ant-menu-light .ant-menu-submenu-title:not(.ant-menu-item-disabled):focus-visible {
    outline: none;
  }

  .ant-menu-inline > .ant-menu-item:focus {
    outline: none;
  }
  .ant-menu-inline > .ant-menu-submenu:focus {
    outline: none;
  }

  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title:focus {
    outline: none;
  }

  a:hover {
    color: black;
  }
}

@media screen and (max-width: 767px) {
  .sider-menu {
    width: 100%;
    padding: 0;
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
      display: none;
    }
    .ant-tabs .ant-tabs-tab {
      padding: 0 0;
    }
    .ant-tabs-tab a {
      padding: 16px 0;
      display: inline-block;
      color: #7a7a7a;
      text-align: center;
      text-decoration: none;
      font-weight: 500;
    }
    .menu-item.active {
      color: #eb3d26;
    }
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::after,
    .ant-tabs-top > .ant-tabs-nav .ant-tabs-nav-wrap::before {
      box-shadow: none;
    }
    .ant-tabs-top > .ant-tabs-nav::before {
      border-bottom: 1px solid #c9c9c9;
    }
  }
}
