.case-progress {
  margin-top: 30px;

  .form-group {
    margin-bottom: 0;
  }

  ul li {
    list-style-type: decimal;
  }
  ul {
    padding-inline-start: 18px;
  }

  li {
    padding-bottom: 20px;
    &::marker {
      font-weight: 500;
    }
  }
  .case-progress__label {
    width: 200px;
    font-weight: 500;
  }
}
