.request-documents {
  .docs-provided {
    .control-label {
      max-width: 300px;
    }
  }
  .docs-provided__container {
    margin-top: 1rem;
  }
}
