.edit-btn {
  float: right;
  height: 1.8rem;
  margin-left: 10px;
  cursor: pointer;
  background-image: url('../../assets/img/edit-button.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}
// .education_document:nth-child(n):after {
//   content: '';
//   display: block;
//   width: 135px;
//   height: 3px;
//   background-color: #ff0000;
//   margin-top: 30px;
//   margin-bottom: 30px;
// }

.RelocationRequestDetails .info-main__item {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  .title {
    margin-bottom: 20px;
  }
}
.GeneralInfo h2 {
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 28px;
}

.responsible-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  .responsible-select-top {
    margin-left: 10px;
    width: 65%;
  }
}

.arsGmtResponsible {
  position: absolute;
  right: 0;
  top: 26px;
  .additional-top__label {
    padding: 0 10px 0 0;
    font-weight: 500;
  }
}

.final-checkbox-wrapper label {
  font-size: 1.4em;
  font-weight: 400;
  margin-top: 1rem;
}

.RequestDetails {
  position: relative;
  &__wrapper {
    position: relative;
    .logo-wrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 6px;
    }
  }

  .updated-at-by__absolute {
    position: absolute;
    top: 20px;
    right: 3.75rem;
    z-index: 2;
  }
  .status-container__select {
    min-width: 12rem;
  }

  .location {
    font-size: 20px;
    font-weight: 700;
  }

  .updated-at-by {
    font-size: 12px;
    font-weight: 700;
  }
  .request-top {
    &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .EmployeeName__wrapper {
      display: flex;

      .logo-wrapper {
        display: flex;
      }

      .smgLogo,
      .wodLogo,
      .user-info {
        padding-left: 12px;
      }

      .smgLogo {
        padding-left: 12px;

        svg {
          height: 30px;
          width: 25px;
        }
      }

      .wodLogo,
      .user-info {
        svg {
          height: 25px;
          width: 25px;
        }
      }
    }
  }
  .request-details__container {
    display: flex;
    min-height: calc(100vh - 190px);
    position: relative;
  }

  .request-details__content {
    width: calc(100% - 230px);
    padding-left: 32px;
    border-left: 1px solid #c9c9c9;
    padding-top: 24px;
  }
  .request-details__content:has(.RelatedProfiles, .relocation-history-details, .employee-comments) {
    .additional-info-top {
      display: none;
    }
  }
}

@media screen and (max-width: 767px) {
  .final-checkbox-wrapper {
    margin-top: 1rem;
  }
  .final-checkbox-wrapper label {
    margin-top: 0;
  }
  .RequestDetails {
    .request-top {
      &__right {
        align-items: flex-start;
      }
    }
    .request-details__container {
      flex-direction: column;
      .request-details__content {
        width: 100%;
        padding-left: 0;
        border-left: unset;
        padding-top: 16px;
      }
    }
  }
  .arsGmtResponsible {
    position: unset;
    justify-content: space-between;
  }
}

@media (max-width: 1200px) {
  .arsGmtResponsible {
    position: unset;
  }
}

@media (max-width: 992px) {
  .RequestDetails {
    .updated-at-by__absolute {
      right: 3vw;
      top: 10px;
    }
  }
}

@media (max-width: 554px) {
  .RequestDetails {
    .updated-at-by__absolute {
      display: none;
    }
  }
}
