.send-info__modal {
  .send-docs__container {
    padding-top: 40px;

    .wrapper .modal-container {
      max-width: 430px;
      min-height: 155px;
    }

    .send-docs__validation-error {
      color: red;
      font-size: 14px;
      padding-bottom: 2px;

      span {
        font-weight: 500;
        font-size: 14px;
      }

      &-link {
        cursor: pointer;
        text-decoration: underline;
      }
    }
    .send-docs__buttons {
      .submit-btn {
        margin-right: 0;
      }
    }
    .custom-checkbox .custom-checkbox__label span {
      background-image: url('../../../assets/img/Checkbox_empty_black.svg');
    }
    .custom-checkbox .custom-control-input:checked + .custom-checkbox__label span {
      background-image: url('../../../assets/img/Checkbox_checked.svg');
    }
  }
}

.send-docs {
  .form-group {
    label {
      flex: unset;
      max-width: unset;
      width: unset;
      padding-left: 0;
    }
  }

  &__buttons {
    position: absolute;
    bottom: 40px;
    right: 40px;
  }
}

.request-employee-documents__container .documents__wrapper .documets__menu .send-info .send-docs__container {
  .send-docs {
    &__buttons {
      .main-btn.btn-form {
        width: unset;
      }

      .send-docs__cancel {
        margin-right: 20px;
      }
    }
  }
}

@media (max-width: 857px) {
  .send-docs__container {
    .wrapper {
      .modal-container {
        padding: 16px 16px 2rem;
      }
    }
  }
  .send-docs {
    &__buttons {
      bottom: 1rem;
      right: 1rem;
    }
  }
}
