.relocated-employees__generation-excel-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 204px;
  img {
    margin-right: 10px;
  }
  &:disabled {
    background: #c4c4c4;
    border: 1px solid #c4c4c4;
  }
}

.relocated-employees__generation-excel-btn.main-btn.btn-back:disabled,
.relocated-employees__generation-excel-btn.main-btn.btn-back[disabled] {
  background: #c4c4c4;
  border: 1px solid #c4c4c4;
}
