.convertation__content {
  .convertation-title {
    font-weight: 600;
    font-size: 20px;
    margin: 0;
  }
  .convertation-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 40px;
  }
  .search-employees .search-form p {
    display: flex;
    justify-content: flex-start;
    padding-left: 0;
  }
  .convertation__button-container {
    position: absolute;
    bottom: 40px;
    right: 40px;
    .main-btn.btn-form {
      margin: 0 0 0 20px;
    }
  }
}

.action-required {
  .merge-candidate {
    .wrapper .modal-container {
      max-width: 486px;
      min-height: 560px;
      padding: 42px 40px 40px;
    }
    .wrapper .exit-container {
      display: none;
    }
  }
}
