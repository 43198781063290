.case-progress__dropdown-container {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  .case-progress__label {
    width: 200px;
    font-weight: 500;
  }
  .case-progress__dropdown {
    width: 300px;
  }
}
