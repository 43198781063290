.current-attachments__container {
  display: flex;

  .current-attachments-block {
    display: flex;
    flex-wrap: wrap;
  }

  label {
    font-weight: 500;
  }

  .download-all__wrapper {
    cursor: pointer;
  }

  .download-all__container {
    svg {
      color: #666666;
      font-size: 24px;
    }
    .anticon-download {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .spinner {
      font-size: 24px;
      height: 24px;
      margin-top: 4px;
    }
    .loading-message {
      font-size: 10px;
      color: #666666;
    }
  }
}

@media (max-width: 767px) {
  .current-attachments__container {
    flex-wrap: wrap;
  }
}
