@import '../../../../../assets/css/variables.scss';

.ant-menu .ant-menu-item-divider {
  overflow: hidden;
  line-height: 0;
  border-color: rgb(5 5 5 / 10%);
  border-style: solid;
  border-width: 0;
  border-top-width: 1px;
  margin-block: 1px;
  margin-left: 10px;
  margin-right: 10px;
  padding: 0;
}

.ant-menu-vertical >.ant-menu-item {
  height: 34px;
  line-height: 34px;
}

.nav-text {
  font-size: 14px;
}

.log-out {
  color: #eb3d26;
}

.pop-up {
  position: absolute;
  top: 64px;
  right: 3.75rem;
  //min-width: 280px;
  //min-height: 150px;
  background-color: $secondary;
  //text-align: right;
  //padding-top: 15px;
  //padding-bottom: 15px;
  //padding-right: 25px;
  //border: 1px solid #c4c4c4;
  box-shadow: rgba(0, 0, 0, 0.1) 1px 4px 10px 0px, rgba(0, 0, 0, 0.06) 1px 4px 30px 0px, rgba(0, 0, 0, 0.06) 1px 1px 24px 0px;

  .nav-list {
    list-style: none;
    margin: 0;

    li a {
      text-decoration: none;
      color: $black;
      font-size: 18px;
      font-weight: 500;
      line-height: 30px;
      letter-spacing: 0em;
      &:hover {
        color: $red;
      }
    }
    li.mobile {
      display: none;
    }
  }
}

@media (max-width: 992px) {
  .pop-up {
    top: 70px;
  }
}
@media (max-width: 768px) {
  .pop-up {
    overflow-y: auto;
    padding-bottom: 3rem;
    max-height: calc(100vh - 100px);
    .nav-list li.mobile {
      display: block;
    }
  }

  .pop-up .nav-list li.mobile .HeaderSearch {
    display: block;
    margin-top: 20px;
    margin-left: 25px;
    margin-bottom: 3rem;
    .search-wrapper {
      input {
        padding: 6px 0 6px 28px;
        width: 240px;
        background-color: #fff;
        font-size: 14px;
      }
      .search-icon {
        display: none;
      }
    }
    .search-results__wrapper {
      display: block;
      position: relative;
      top: 0;
      margin-top: 10px;
      width: 100%;
      &.hide {
        display: none;
      }
      .search-results__item {
        .employee-name {
          font-size: 14px;
          line-height: 18px;
          color: #333;
          display: flex;
          justify-content: flex-end;
        }
        &.no-content {
          font-size: 14px;
          line-height: 18px;
          display: flex;
          justify-content: flex-end;
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .pop-up__disabled {
    display: none;
  }
}

@media (max-width: 576px) {
  .pop-up {
    &__disabled {
      right: -576px;
      min-height: 100vh;
      transition-property: all;
      transition-duration: 700ms;
    }

    &__active {
      position: absolute;
      bottom: 0;
      min-height: 100vh;
      min-width: 100%;
      right: 0;
      transition-property: all;
      transition-duration: 700ms;
    }

    .desktop {
      display: none;
    }
    .nav-list {
      padding: 0;
    }
  }
}

@media (max-width: 375px) {
  .pop-up {
    &__disabled {
      right: -375px;
    }
    &__active {
      transition-duration: 700ms;
    }
  }
}

@media (max-width: 320px) {
  .pop-up {
    &__disabled {
      right: -320px;
    }
  }
}
