.vacAppointment-container {
  .vacAppointment-title__wrapper {
    display: flex;
    justify-content: space-between;
    max-width: 500px;

    .vacAppointment-title__container {
      display: flex;
    }

    .vacAppointment-id {
      display: flex;
      font-weight: 500;
      color: #c4c4c4;
      margin-left: 4px;
    }

    .cancel-button {
      display: flex;
      position: unset;
      margin-left: unset;
      margin-bottom: unset;
      margin-top: 4px;
    }
  }

  .note-container {
    .note-cancel__container {
      .cancel-button {
        margin-top: 10px;
        margin-left: 1rem;
        position: unset;
      }
    }
  }

  .delete-form-container {
    display: flex;
    margin-left: unset;
    margin-bottom: unset;
    margin-top: 4px;
  }
}

@media (max-width: 767px) {
  .vacAppointment-container .vacAppointment-title__wrapper {
    max-width: 100%;
  }
}
