.add-item-to-legalization-table {
  .responsible-select-top {
    width: 58%;
    min-width: 58%;
  }

  .wrapper .modal-container .form-group input {
    width: 100%;
  }

  .form-group .ant-datepicker-wrapper {
    flex: 0 0 58%;
    max-width: 58%;
  }

  .add-item-to-legalization-table__button {
    display: flex;
    align-items: center;
    width: 316px;
    img {
      margin-right: 10px;
    }
  }
  .wrapper .exit-container {
    display: none;
  }
  .add-item-to-legalization-table__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 45px;
    font-size: 20px;
    h4 {
      margin: 0;
      font-size: 19px;
    }
    img {
      cursor: pointer;
    }
  }
  .add-item-to-legalization-table__content {
    min-height: 569px;
    .form {
      .error-container {
        width: 100%;
        .label-error {
          display: none;
        }
        .error {
          justify-content: flex-start;
        }
      }
    }
  }

  .wrapper .col-md-9 {
    flex: unset;
    max-width: 486px;
  }

  .add-item-to-legalization-table__label {
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    margin-right: 40px;
  }
  .add-item-to-legalization-table__type-document {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .select__type-document {
    width: 100%;
  }
  .add-item-to-legalization-table__form {
    padding-bottom: 4rem;
  }
  .form {
    .add-item-to-legalization-table__button-container {
      position: absolute;
      bottom: 50px;
      right: 2.5rem;
      .main-btn.btn-form {
        margin: 10px 0 0 24px;
      }
    }
  }

  .wrapper .modal-container {
    padding: 2rem 2.5rem 2rem 2.5rem;
  }
  .exit-container {
    display: none;
  }
  &__button-container {
    display: flex;
    justify-content: space-between;
    .main-btn.btn-form {
      margin-right: 0;
    }
  }

  &__wrapper {
    .cityId {
      position: relative;
    }
    .employeeId::placeholder {
      text-overflow: ellipsis;
    }
  }

  .wrapper .modal-container .form-group:has(.error) {
    margin-bottom: 9px;
    .ant-datepicker-wrapper,
    .form-width {
      border: 1px solid #ff0000;
    }
  }

  .search-employees:has(.error) {
    .search-container {
      border: 1px solid #ff0000;
    }
  }

  .search-employees {
    .label-empty {
      flex: 0 0 42%;
    }
    .search-form {
      p {
        padding: 0 15px 0 0;
        flex: 0 0 42%;
      }
    }
    .error {
      color: #ff0000;
      padding-left: 42%;
    }
  }

  .form-error-container-relocation:has(.error) {
    .responsible-select-top > div:first-of-type {
      border-color: #ff0000;
    }
  }

  .wrapper .modal-container .form-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 25px;

    label {
      font-weight: 500;
      max-width: unset;
      width: 42%;
      flex: 0 0 42%;
      padding-left: 0;
    }
    .text-field__wrapper {
      width: 58%;
      flex: 0 0 58%;
    }
    .react-datepicker__input-container {
      input {
        width: 100%;
      }
    }

    .error {
      display: block;
      color: #ff0000;
      flex: 0 0 100%;
      text-align: start;
      padding-left: 42%;
      font-size: 12px;
      line-height: 16px;
    }
    .custom-checkbox__label {
      width: 100%;
    }
  }
}

.add-item-to-legalization-table__buttons {
  margin-bottom: 30px;
  .tab-button {
    font-size: 16px;
    font-weight: 400;
    padding-bottom: 10px;
  }
  .tab-button-left {
    width: 38%;
  }
  .tab-button-right {
    width: 62%;
  }
  .tab-active {
    background: #fff;
    color: #eb3d26;
    border: none;
    border-bottom: 2px solid #eb3d26;
    font-weight: 600;
  }
  .tab-disabled {
    background: #fff;
    color: #000000;
    border: none;
    border-bottom: 1px solid #c9c9c9;
  }

  .tab-disabled:hover:not(.tab-active) {
    background: #fff;
    color: #000000;
    opacity: 0.6;
    border: none;
    border-bottom: 1px solid #c9c9c9;
  }
}

@media (max-width: 769px) {
  .add-item-to-legalization-table {
    .form {
      .add-item-to-legalization-table__button-container {
        position: absolute;
        bottom: 20px;
        right: 1.5rem;
      }
    }

    .wrapper {
      .modal-container {
        padding: 1rem 1.5rem 1rem 1.5rem;

        .form-group__column {
          flex-direction: row;
        }

        .form-group {
          label {
            width: 34%;
            flex: 0 0 34%;
          }

          .error {
            padding-left: 38%;
          }
        }
      }
    }
  }
}

@media (max-width: 405px) {
  .add-item-to-legalization-table {
    .wrapper {
      .modal-container {
        .form-group {
          .error {
            padding-left: 39%;
          }
        }
      }
    }
  }

  .add-item-to-legalization-table__buttons {
    .tab-button-left {
      width: 40%;
    }

    .tab-button-right {
      width: 60%;
    }
  }
}
