.ant-table-thead
> tr
> th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
  display: none;
}

.ant-table-column-sorter {
  display: none;
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters.ant-table-cell-fix-left:hover {
  background: #f0f0f0;
}
.ant-table-wrapper .ant-table-thead th.ant-table-column-sort {
  background: #f0f0f0;
}

.ant-dropdown-menu-title-content {
  span {
    font-size: 14px;
  }
}

.ant-table-wrapper {
  .ant-table-filter-column {
    justify-content: unset;
  }

  .ant-table-column-title {
    flex: unset;
  }
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-has-sorters:hover {
  background: #f0f0f0;
}

.ant-table-wrapper .ant-table-pagination.ant-pagination {
  margin: 2rem 0 0 0;

  .anticon svg {
    width: 13px;
    height: 13px;
  }
}

.ant-dropdown .ant-dropdown-menu {
  padding: 0;

  .ant-dropdown-menu-item {
    color: #525252;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    padding: 5px 12px;

    &:hover {
      color: #000000;
      font-weight: 400;
    }

    .ant-dropdown-menu-title-content {
      span {
        font-size: 14px;
      }
    }
  }
}

.ant-table-content {
  .table__body-item-content {
    font-size: 14px;
  }

  .ant-table-additional-info-column {
    display: flex;
    justify-content: center;

    .ant-table-additional-info-icon {
      display: flex;
    }

    .ant-table-column-title {
      margin-bottom: 2px;
      margin-right: 2px;
      flex: none;
    }
  }

  a:hover {
    border-bottom: 1px solid #eb3d26;
    color: black;
  }

  .ant-table-thead {
    position: sticky;
    top: 73px;
    z-index: 5;

    .ant-table-cell {
      font-weight: 500;
      font-size: 15px;
      color: #7a7a7a;
      background: #ffffff;
      border-bottom: 2px solid #eb3d26;

      &:hover {
        background: rgb(240, 240, 240);
      }
    }

    .ant-table-cell:first-child {
      border-radius: 0;
    }

    .ant-table-cell:last-child {
      border-radius: 0;
    }

    .ant-table-column-title {
      color: #7a7a7a;
      font-weight: 500;
      font-size: 15px;
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      vertical-align: top;

      .ant-table-cell {
        font-size: 14px;
      }
    }
  }
}

.ant-table-additional-info {
  fill: #555555;

  &:hover {
    fill: #eb3d26;
    cursor: pointer;
  }
}

.ant-pagination {
  .ant-pagination-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    display: flex;
    align-items: center;
  }
}

.ant-btn > span,
.ant-dropdown .ant-table-filter-dropdown .ant-checkbox-wrapper + span {
  font-size: 14px;
}

.ant-pagination-item a {
  font-size: 14px;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: none;
  padding-top: 110px;

  .spinner {
    position: sticky;
    top: 40%;
  }
}

.ant-table-wrapper .ant-table-filter-trigger {
  margin-inline: 4px -2px;
}

@media screen and (max-width: 992px) {
  .mobile-view.ant-table-wrapper .ant-table-content .ant-table-tbody .ant-table-row:nth-child(2n) {
    background: #f3f3f3;
  }
  .mobile-view.ant-table-wrapper
  .ant-table-content
  .ant-table-tbody
  .ant-table-row:nth-child(2n)
  .ant-table-cell-fix-left {
    background: #f3f3f3;
  }
  .mobile-view.ant-table-wrapper {
    .ant-table-content {
      colgroup {
        display: none;
      }

      .ant-table-thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      .ant-table-thead tr {
        display: block;
        margin-bottom: 20px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      }

      .ant-table-tbody {
        .ant-table-cell {
          border-bottom: 1px solid #ddd;
          font-size: 14px;
          text-align: right;
          padding: 10px 20px;
          display: flex;
          justify-content: space-between;

          .custom-checkbox span {
            margin-right: 0;
          }

          .table__body-item-content {
            width: 100%;
            font-size: 14px;
            text-align: right;
            display: flex;
            gap: 10px;
            justify-content: space-between;

            &::before {
              content: attr(data-label);
              font-weight: bold;
              display: flex;
              text-align: left;
            }

            &:last-child {
              border-bottom: 0;
            }
          }
        }
      }
    }
  }
}

.ant-dropdown-trigger:focus-visible {
  outline: none;
}
