.employee-comments {
  .ant-table-wrapper {
    .ant-table-content {

      .ant-table-tbody .ant-table-row {
        .ant-table-cell {
          * {
            font-size: 14px;
          }

          p {
            margin-bottom: 0;
          }

          dl, ol, ul {
            padding-inline: 26px;
          }
        }
        .ant-table-cell:nth-child(1) div {
          font-weight: 600;
        }
      }

      .ant-table-thead {
        top: calc(75px + 84px);
      }

      .ant-table-cell {
        font-size: 14px;
      }

      .ant-table-cell:first-child {
        text-align: left;
      }
    }

  }
}