.currency-form {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  .responsible-select-top {
    max-width: 4rem;
  }
  .currency-cost {
    margin-bottom: 1px;
    min-width: 12rem;
    width: 13rem;
  }
  .currency-container {
    display: flex;
  }
}

.form-group #currency {
  margin-right: 5px;
  min-width: 5rem;
  height: calc(1.5em + 0.75rem + 3px);
}

.responsible-select-top {
  display: inline-block;
}

@media (min-width: 768px) {
  .form-group.currency-form {
    .responsible-select-top {
      flex: 0 0 16.666667%;
      min-width: 16.666667%;
    }
    .currency-container {
      max-width: 300px;
      width: 100%;
      justify-content: space-between;
    }
  }
}

@media (max-width: 767px) {
  .currency-form {
    flex-wrap: wrap;
    width: 100%;
    .currency-container {
      display: flex;
      width: 93%;
    }
  }
  .form-group {
    .currency-container {
      .form-control {
        margin-left: 7px;
      }
    }
  }
  .form-group.currency-form {
    .responsible-select-top {
      padding: 0;
    }
    .currency-container {
      width: 100%;
      justify-content: space-between;
    }
  }
}
