.request-employee-documents__container {
  .underlined {
    margin-bottom: 9px;
  }
  .documents__wrapper {
    display: flex;
    min-height: 500px;
    .documets__menu {
      width: 200px;
      display: flex;
      flex-direction: column;
      .send-info {
        padding: 0 18px;
        .main-btn.btn-form {
          margin: 0;
          width: 100%;
        }
      }
    }
    .documets__content {
      width: calc(100% - 200px);
      padding-bottom: 9rem;
      padding-left: 50px;
      border-left: 1px solid #c9c9c9;
    }
  }
  .form-group .control-label,
  .control-label {
    min-width: 12rem;
  }
  .main-btn.btn-form {
    margin: 10px 20px 10px 0;
  }
  .textarea-group .text-area-form-width {
    max-width: calc(12rem + 300px);
  }
}

@media (max-width: 767px) {
  .request-employee-documents__container {
    .documents__wrapper {
      flex-direction: column;
      position: relative;
      .documets__menu {
        width: 100%;
        flex-direction: column-reverse;
        .send-info {
          width: 300px;
          position: absolute;
          bottom: 0;
          top: unset;
          padding: 0;
        }
      }
      .documets__content {
        width: 100%;
        padding-left: 0;
        border-left: none;
        padding-bottom: 1rem;
      }
    }
  }
}

@media (max-width: 544px) {
  .request-employee-documents__container {
    .documents__wrapper {
      .documets__menu {
        .send-info {
          width: 100%;
        }
      }
    }
  }
}
