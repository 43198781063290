@import '../../../../assets/css/variables.scss';

.clear-filters__container {
  display: flex;
  justify-content: flex-end;
  height: 32px;
  width: 100px;
  border: 1px solid $red;
  border-start-start-radius: 6px;
  border-end-start-radius: 6px;
  border-inline-end-width: 1px;
  border-start-end-radius: 6px;
  border-end-end-radius: 6px;
  padding: 4px 12px;
  margin-bottom: 4px;

  .clear-filters {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #eb3d26;
  }

  .clear-filters-icon {
    margin-left: 5px;

    path {
      fill: #eb3d26;
    }
  }

  .clear-filters:hover {
    color: #c83420;
    cursor: pointer;

    .clear-filters-icon {
      path {
        fill: #c83420;
      }
    }
  }
}
