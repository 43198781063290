.RelatedProfiles {
  .table-wrapper .table_ .table__body .case-item__fullname-with-tooltip {
    font-weight: bold;
    cursor: help;
  }
  .add-relative__wrapper {
    display: none;
  }
  .relative-wrapper {
    position: relative;
    .form-group {
      .form-error-container-relocation {
        .form-control {
          width: 100%;
          padding-left: 15px;
        }
        .form-control:disabled {
          padding-left: 0px;
        }
      }
    }
  }
  .add-relative__wrapper {
    .main-btn.btn-form {
      margin: 10px 0 10px 0;
    }
  }
  .add-relative__wrapper.show {
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba($color: #000000, $alpha: 0.7);
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 10;
    .add-relative {
      background: #fff;
      border: 1px solid #ff0000;
      border-radius: 5px;
      padding: 20px;
      min-width: 320px;
      width: 100%;
      max-width: 530px;
      .relative-title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 20px;
        padding-left: 15px;
      }
      .form-group {
        align-items: center;
        label {
          // width: 50%;
          margin-bottom: 0;
          max-width: 100%;
          flex: 0 0 40%;
          padding-bottom: 10px;
          padding-left: 0;
        }
        .text-field__wrapper {
          width: 100%;
          max-width: 100%;
        }
        .responsible-select-top,
        input {
          width: 100%;
          max-width: 100%;
          // flex: 0 0 60%;
          // margin-left: 15px;
        }
        // .form-control {
        //   margin-left: 15px;
        // }
      }
    }
  }

  .form-group .error-container .error {
    width: 100%;
    display: flex;
    justify-content: flex-start;
  }
  .form-group .error {
    padding-left: 0;
    padding-top: 5px;
  }
  .form-group .error-container .label-error {
    width: 0%;
    max-width: 0%;
    flex: 0 0 0%;
  }
  .icon-bin {
    cursor: pointer;
  }
}
.relative-wrapper.error::after {
  content: 'Spouse already exists';
  color: #ff0000;
  position: absolute;
  top: 0;
  right: 0;
}

.notDisabled {
  pointer-events: visible;
}

.toggle-button {
  padding: 8px;
  min-height: 40px;
  font-weight: 700;
  width: 50%;
  font-size: 18px;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  transition: opacity 0.3s ease-in-out;
}

.toggle-button-left {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.toggle-button-right {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.toggle-button__wrapper {
  display: flex;
  margin-bottom: 20px;
  width: 100%;
}

.sub-cancel__butt {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.tab-disabled {
  color: #ec2227;
  background: #fff;
  opacity: 0.9;
  border: 1px solid #ec2227;
}

.tab-active {
  background: #ec2227;
  color: #fff;
  border: 1px solid #ec2227;
}

.tab-disabled:hover:not(.tab-active) {
  color: #fff;
  background-color: rgb(201, 15, 15);
  opacity: 0.9;
  border: 1px solid rgb(201, 15, 15);
}

.disabledTab {
  opacity: 0.3;
  pointer-events: none;
}

@media (max-width: 767px) {
  .RelatedProfiles {
    .responsible-select-top,
    .text-field__wrapper {
      padding: 0;
    }
  }

  .RelatedProfiles .add-relative__wrapper.show .add-relative .relative-wrapper .form-group.radio-line .control-label {
    flex: unset;
  }
}
