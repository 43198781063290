.vacAppointment-container:not(:last-child):after {
  content: '';
  display: block;
  width: 135px;
  height: 3px;
  background-color: #ff0000;
  margin-top: 30px;
  margin-bottom: 30px;
}

.VacAppointment {
  .vacApointment-form {
    .ant-datepicker-wrapper, .time-input {
      min-width: 16rem;
    }
  }
  .notify__container {
    display: flex;
    align-items: center;
    .button {
      margin-left: 1.8rem;
    }
  }
  .visa-gathered {
    .form-group {
      display: flex;
      align-items: center;
      margin-top: 1rem;
    }
    .radio-line .control-label {
      margin: 0 5.55rem 0 0;
    }
    label {
      flex: unset;
      max-width: unset;
    }
  }
}
