.employee-feedback {
  max-width: 1280px;
  margin: 0 auto;

  .questionnaire-answer {
    border-radius: 5px;
    background-color: #fff;
    box-shadow: 2px 2px 6px 3px rgba(203, 203, 203, 0.25);
    .question-answer {
      position: relative;
      padding: 14px 20px 14px;
      .answer__divider {
        position: absolute;
        right: 20px;
        left: 20px;
        bottom: 0;
        margin: 0;
      }
    }

    .question-block .question-answer:last-child .answer__divider {
      display: none;
    }

    .submit-btn__container {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 10px;
    }
  }

  .custom-radio {
    margin-bottom: 0;
  }

  // radio-group
  .custom-radio .relocation-group {
    margin-bottom: 6px;
  }
  .custom-radio .control-label {
    max-width: 100%;
  }
  .relocation-group .relocation-group-radio {
    align-items: flex-start;
  }
  .custom-radio .control-label {
    margin: 0 0 10px;
  }
  .custom-radio label {
    margin: 0;
  }

  //checkbox-group

  .checkboxes-vertical {
    gap: 6px;
  }

  label {
    margin-bottom: 10px;
  }

  //textarea

  .form-group .control-label {
    max-width: 100%;
  }
  .textarea-group textarea {
    margin-top: 0;
  }
  //dates
  .date-field {
    flex-wrap: wrap;

    .control-label {
      padding-bottom: 10px;
    }
  }
}

@media (min-width: 768px) {
  .employee-feedback {
    //textarea

    .textarea-group .text-area-form-width {
      max-width: 100%;
    }
    // input
    .form-input-width {
      max-width: 100%;
    }

    //dropdown validation
    .form-group .responsible-select-top {
      min-width: 300px;
      max-width: 300px;
    }
  }
}
