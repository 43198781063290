.address-info {
  .address-info__container:not(:last-child)::after {
    content: '';
    display: block;
    width: 135px;
    height: 3px;
    background-color: #ff0000;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
