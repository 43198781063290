@import '../../../../assets/css/variables';

//.quick-contact-us__tooltip {
//  min-width: unset;
//  width: 155px;
//}

.btn-quick-contact-us {
  width: 44px;
  height: 44px;
  position: fixed;
  bottom: 68px;
  z-index: 99;
  background-color: #FFFFFF;
  border-radius: 100%;
  border: none;
}

.btn-contact-us__moved-position {
  transition: all 0.5s ease-out;
  transform: none;
  pointer-events: visible;
  right: calc((100vw - 1620px) / 4 + 60px);
}

.btn-contact-us__default-position {
  transition: all 0.5s ease-out;
  transform: none;
  pointer-events: visible;
  right: calc((100vw - 1620px) / 4);
}

@media (max-width: 1620px) {
  .btn-contact-us__default-position {
    right: 3.75rem;
  }
  .btn-contact-us__moved-position {
    right: calc(3.75rem + 60px);
  }
}

@media (max-width: 992px) {
  .btn-contact-us__default-position {
    right: 3vw;
  }
  .btn-contact-us__moved-position {
    right: calc(3vw + 60px);
  }
}

