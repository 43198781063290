.btn-form {
  border: 1px solid white;
  display: inline-block;
}

.btn-disabled {
  opacity: 50%;
}

.main-btn.btn-back.submit-btn-disabled {
  background: #c4c4c4;
  border: 1px solid #c4c4c4;
}

.main-btn.btn-back.isSubmitting {
  color: transparent;
}

.main-btn.btn-back.isSubmitting::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px;
  border-radius: 50%;
  border: 2px solid #d8d8d8ba;
  border-top-color: #ffffff;
  animation: button-loading-spinner 1s linear infinite;
}

@keyframes button-loading-spinner {
  from {
    transform: rotate(0turn);
  }

  to {
    transform: rotate(1turn);
  }
}

.main-btn.btn-back.submit-btn-disabled:hover {
  cursor: not-allowed;
}

.main-btn.btn-back.submit-btn-disabled:hover::before {
  cursor: not-allowed;
  opacity: 0;
}

.cancel-button {
  position: absolute;
  left: 57%;
  top: 0;
  display: flex;
  margin-left: 1.8rem;
}

@media (max-width: 992px) {
  .cancel-button {
    left: 60%;
  }
}

@media (max-width: 767px) {
  .cancel-button {
    left: unset;
    right: 0;
  }
}
