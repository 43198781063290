.select-forms-to-display {
  &__forms-container {
    margin-bottom: 1rem;
  }
  &__title {
    margin-bottom: 1rem;
  }
  .displayVacIsRequired {
    margin-bottom: 1.5rem;
  }
  form {
    padding-bottom: 70px;
  }
  .form__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-flow: column;
    gap: 10px;
    div {
      display: flex;
    }
  }
  .form-group {
    margin-bottom: 6px;
  }
  .wrapper .modal-container {
    padding: 2rem 1.5rem 2rem 1.5rem;
    min-height: 400px;
  }
  .exit-container {
    display: none;
  }
  &__button-container {
    position: absolute;
    bottom: 24px;
    right: 32px;
    display: flex;
    justify-content: space-between;
    .main-btn.btn-form {
      margin-right: 0;
    }
    .main-btn.btn-form.select-documents__cancel {
      margin-right: 16px;
    }
  }
  .custom-control {
    min-height: unset;
  }

  .checkbox-reverse {
    .control-label {
      padding-left: 0.5rem;
    }
  }
  .wrapper .modal-container .form-group label {
    width: 100%;
    margin-bottom: 0;
    max-width: 100%;
    flex: 0 0 100%;
  }

  .wrapper .modal-container .checkbox-reverse label {
    font-weight: 400;
  }

  .wrapper .modal-container .form-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    // flex-wrap: wrap;
    label {
      max-width: unset;
      font-size: 14px;
    }
    .error {
      color: red;
      flex: 0 0 100%;
      text-align: start;
      padding-left: 55%;
    }
    .custom-checkbox__label {
      width: 100%;
    }
  }
  .wrapper .modal-container .date {
    margin-top: 1rem;
    .control-label {
      font-size: 16px;
      width: 55%;
      flex: 0 0 55%;
    }
    .ant-datepicker-wrapper {
      flex: 0 0 40%;
      max-width: 40%;
    }
  }
}

@media (max-width: 1600px) {
  .select-forms-to-display {
    .form__container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-flow: column;
      gap: 10px;
      div {
        display: flex;
      }
    }
  }
}

@media (min-width: 768px) {
  .select-forms-to-display {
    .form-group .responsible-select-top {
      width: 60%;
      min-width: 60%;
    }
  }
}

@media (max-width: 767px) {
  .select-forms-to-display {
    .select-documents__container {
      .form-group__column {
        margin-left: 15px;
      }
      .form-group {
        .form-control {
          margin-left: 0;
        }
      }
    }

    .wrapper .modal-container .select-documents__container .form-group {
      .error {
        padding-left: 0;
      }
    }
    .wrapper .modal-container .date .form-group {
      flex-direction: column;
    }
    .wrapper .modal-container .form-group {
      // flex-direction: column;
      align-items: flex-start;

      label {
        width: 100%;
        margin-bottom: 4px;
      }
      input {
        width: 90%;
      }
      .form-width {
        width: 100%;
      }
    }
  }
}
