.additional-info-top {
  position: absolute;
  background-color: #fff;
  top: 26px;
  right: 0;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  justify-content: space-between;
  .main-btn.btn-form {
    margin-right: 0;
  }
  .additional-info-top__show-notes {
    margin: 0 10px;
  }
  &__show-notes {
    width: 1.1rem;
    cursor: pointer;
    img {
      width: 100%;
      transform: scaleX(-1);
    }
  }
  .responsible-top strong {
    padding: 0 10px 0 0;
  }
  &__status-container {
    display: flex;
    align-items: center;
    .status-container__title {
      padding: 0 10px 0 0;
    }
  }
  .additional-top__label {
    font-weight: 500;
  }
}

.title-location {
  .additional-top__label {
    font-weight: 500;
  }
}

@media screen and (max-width: 1200px) {
  .additional-info-top {
    position: unset;
    top: 26px;
    right: 0;
    display: unset;
    .responsible-top {
      justify-content: flex-start;
      .additional-top__label {
        width: 150px;
      }
    }
    .status-container {
      display: flex;
      align-items: center;
      .status-container__title {
        width: 150px;
      }
    }
    .add-notes {
      margin-bottom: 30px;
    }
  }
}

@media (max-width: 992px) {
  .additional-info-top__status-container {
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}

@media screen and (max-width: 767px) {
  .additional-info-top {
    display: none;
  }
}
