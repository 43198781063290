@import '../../../assets/css/variables.scss';

.relocation-container {
  .relocation-header {
    .relocation-header__first-container {
      max-width: 694px;
      .relocation-header__title {
        font-size: 40px;
        font-weight: 700;
        line-height: 52px;
        margin: 0;

        &::after {
          content: '';
          display: block;
          width: 70px;
          height: 2px;
          background-color: $red;
          margin-top: 6px;
        }
      }
      .relocation-header__content {
        padding: 42px 0;
        margin: 0;
        font-size: 18px;
        line-height: 24px;
        .relocation-header__content-links {
          font-size: 18px;
          line-height: 24px;
          color: $red;
          border-bottom: 1px solid $red;
        }
      }
      .main-btn.btn-form {
        margin: 0;
      }
    }

    .relocation-header__img {
      position: absolute;
      top: 75px;
      right: 0;
      z-index: -1;
    }
  }

  .relocation-content {
    margin-top: 150px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-auto-rows: 1fr;
    box-sizing: border-box;
    gap: 1px;
  }
  &__spinner {
    height: 300px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 992px) {
  .relocation-container {
    .relocation-header {
      .relocation-header__img {
        display: none;
      }
    }
    .relocation-content {
      margin-top: 52px;
      grid-template-columns: 1fr 1fr;
    }
  }
}

@media (max-width: 768px) {
  .relocation-container {
    .relocation-header {
      .relocation-header__img {
        display: none;
      }
      .relocation-header__first-container .relocation-header__title {
        font-size: 30px;
      }
    }
    .relocation-content {
      margin-top: 52px;
      grid-template-columns: 1fr;
    }
  }
}

@media (max-width: 544px) {
  .relocation-container {
    .relocation-header {
      .relocation-header__first-container .relocation-header__title {
        font-size: 26px;
        line-height: 42px;
      }
    }
  }
}

@media (min-width: 1700px) {
  .relocation-container {
    .relocation-header {
      .relocation-header__first-container {
        max-width: 800px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .relocation-container {
    .relocation-header {
      .relocation-header__img {
        opacity: 0.4;
      }
    }
  }
}
