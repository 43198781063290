.header-inner {
  .settings__modal {
    .settings__button-container {
      position: absolute;
      bottom: 2rem;;
      right: 2.5rem;

      .main-btn.btn-form {
        margin: 10px 0 0 24px;
      }
    }

    .settings__form {
      padding-bottom: 4rem;
    }

    .settings__title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 38px;

      h4 {
        margin: unset;
        font-size: 20px;
      }

      img {
        cursor: pointer;
      }

      .underlined:after {
        width: 60px;
      }
    }
  }

  .wrapper .modal-container {
    max-width: 506px;
    min-height: 258px;
    padding: 2rem 2.5rem 2rem 2.5rem;

    .exit-container {
      display: none;
    }
  }
}


@media (max-width: 769px) {
  .header-inner {

    .settings__modal {
      .settings__title {
        margin-bottom: 32px;
      }

      .settings__button-container {
        position: absolute;
        bottom: 20px;
        right: 1.5rem;
      }
    }

    .wrapper {
      .modal-container {
        padding: 1rem 1.5rem 1rem 1.5rem;
        min-height: 234px;

        .form-group__column {
          flex-direction: row;
        }

        .form-group {
          label {
            width: 34%;
            flex: 0 0 34%;
          }
        }
      }
    }
  }
}
