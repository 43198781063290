.EmploymentInfo {
  //   .employment-info-container:after {
  //     content: '';
  //     display: block;
  //     width: 135px;
  //     height: 3px;
  //     background-color: #ff0000;
  //     margin-top: 30px;
  //     margin-bottom: 30px;
  //   }

  .employment-info-container:not(:last-child):after {
    content: '';
    display: block;
    width: 135px;
    height: 3px;
    background-color: #ff0000;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .form-group.date-field-active {
    display: flex;
    flex-wrap: wrap;
    // align-items: center;
  }

  .text-field {
    .form {
      display: flex;
      flex-wrap: wrap;
    }
  }
}
