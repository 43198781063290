.add-notes {
  .add-notes__underlined::after {
    content: '';
    display: block;
    width: 51px;
    height: 2px;
    background-color: #eb3d26;
    margin-top: 5px;
  }

  .form-group .form-error-container {
    padding-left: 0;
  }

  .wrapper .modal-container .status-notes {
    .form-group textarea {
      min-height: 188px;
      max-height: 188px;
    }
  }

  .wrapper .modal-container .form-group textarea {
    min-height: 133px;
    max-height: 133px;
  }

  .form-group .ant-datepicker-wrapper {
    flex: 0 0 40%;
    max-width: 40%;
  }

  .add-notes__button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 41px;
    width: 167px;
    font-size: 16px;
  }
  .wrapper .exit-container {
    display: none;
  }
  .add-item-to-legalization-table__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 28px;

    h4 {
      margin: 0;
      font-size: 24px;
      font-weight: 600;
    }
    img {
      cursor: pointer;
    }
  }
  .add-item-to-legalization-table__content {
    min-height: 365px;
    padding-bottom: 3rem;
    .form {
      .error-container {
        width: 100%;
        .label-error {
          display: none;
        }
        .error {
          justify-content: flex-start;
        }
      }
    }
  }

  .wrapper .col-md-9 {
    flex: unset;
    max-width: 620px;
  }

  .add-item-to-legalization-table__label {
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    margin-right: 40px;
  }
  .add-item-to-legalization-table__type-document {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .select__type-document {
    width: 100%;
  }
  .form {
    .add-item-to-legalization-table__button-container {
      position: absolute;
      bottom: 2rem;
      right: 2.5rem;
      .main-btn.btn-form {
        margin: 10px 0 0 24px;
      }
    }
  }

  .wrapper .modal-container {
    padding: 2rem 2.5rem 2rem 2.5rem;
  }
  .exit-container {
    display: none;
  }
  &__button-container {
    display: flex;
    justify-content: space-between;
    .main-btn.btn-form {
      margin-right: 0;
    }
  }

  &__wrapper {
    .cityId {
      position: relative;
    }
    .employeeId::placeholder {
      text-overflow: ellipsis;
    }
  }

  .wrapper .modal-container .form-group:has(.error) {
    margin-bottom: 4px;
    .ant-picker {
      border: 1px solid #ff0000;
    }
  }

  .form-error-container-relocation:has(.error) {
    .responsible-select-top > div:first-of-type {
      border-color: #ff0000;
    }
  }

  .wrapper .modal-container .checkbox-reverse {
    padding-left: 0;
    .custom-checkbox {
      display: flex;
    }
  }

  .wrapper .modal-container .form-group:has(.error-textarea) {
    flex-direction: column-reverse;
    margin-bottom: 4px;
    .label-message {
      max-width: 100%;
    }

    textarea {
      border: 1px solid #ff0000;
    }
  }

  //.textarea-group {
  //  flex-direction: column-reverse;
  //  .label-message:has(.error-textarea) {
  //    .wrapper .modal-container .form-group {
  //      margin-bottom: 2px;
  //    }
  //  }
  //}

  .wrapper .modal-container .form-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 20px;

    label {
      max-width: unset;
      width: 37%;
      flex: 0 0 37%;
      padding-left: 0;
      margin-bottom: 0;
    }
    input {
      // width: 100%;
      // flex: 0 0 100%;
      font-size: 14px;
    }
    textarea {
      font-size: 14px;
      margin-top: 0;
      min-width: 100%;
      max-width: 100%;
      resize: none;
    }
    .error-textarea {
      display: block;
      color: #ff0000;
      flex: 0 0 100%;
      text-align: start;
      font-size: 12px;
      line-height: 16px;
    }
    .label-message {
      padding: 0;
    }

    .react-datepicker__input-container {
      input {
        width: 100%;
      }
    }

    .error {
      display: block;
      color: #ff0000;
      flex: 0 0 100%;
      text-align: start;
      padding-left: 0;
      font-size: 12px;
      line-height: 16px;
    }
    .custom-checkbox__label {
      width: 100%;
    }
  }
}

.add-notes .wrapper .modal-container .form-group.date-field .error {
  padding-left: 37%;
}

.add-notes__buttons {
  display: flex;
  margin-bottom: 15px;

  .add-notes__tab-underline {
    flex: 1;
    border-bottom: 1px solid #c9c9c9;
  }

  .tab-button {
    font-size: 16px;
    font-weight: 400;
    padding: 0 15px 10px 15px;
  }
  .tab-active {
    cursor: default;
    background: #fff;
    color: #eb3d26;
    border: none;
    border-bottom: 2px solid #eb3d26;
    font-weight: 600;
  }
  .tab-disabled {
    background: #fff;
    color: #000000;
    border: none;
    border-bottom: 1px solid #c9c9c9;
  }

  .tab-disabled:hover:not(.tab-active) {
    background: #fff;
    color: #000000;
    opacity: 0.6;
    border: none;
    border-bottom: 1px solid #c9c9c9;
  }
}

@media (max-width: 769px) {
  .add-item-to-legalization-table {
    .form {
      .add-item-to-legalization-table__button-container {
        position: absolute;
        bottom: 20px;
        right: 1.5rem;
      }
    }

    .wrapper {
      .modal-container {
        padding: 1rem 1.5rem 1rem 1.5rem;

        .form-group__column {
          flex-direction: row;
        }

        .form-group {
          label {
            width: 34%;
            flex: 0 0 34%;
          }

          .error {
            padding-left: 38%;
          }
        }
      }
    }
  }
}

@media (max-width: 405px) {
  .add-item-to-legalization-table {
    .wrapper {
      .modal-container {
        .form-group {
          .error {
            padding-left: 39%;
          }
        }
      }
    }
  }

  .add-item-to-legalization-table__buttons {
    .tab-button-left {
      width: 40%;
    }

    .tab-button-right {
      width: 60%;
    }
  }
}

@media (min-width: 768px) {
  .status-notes,
  .set-up-reminder {
    .textarea-group .text-area-form-width {
      max-width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .add-item-to-legalization-table__content {
    .form-group .form-error-container {
      padding-right: 0;
    }
    .add-notes__buttons .tab-button {
      padding: 0 6px 10px 6px;
    }
  }
  .add-notes .wrapper .modal-container .form-group label {
    max-width: unset;
    width: unset;
    flex: unset;
  }
}

@media (max-width: 544px) {
  .add-notes .wrapper .modal-container {
    padding: 1rem 1rem 1rem 1rem;
  }
  .add-notes .form-group .ant-datepicker-wrapper {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .add-item-to-legalization-table__content {
    .form-group .ant-datepicker-wrapper {
      padding: 0;
    }
  }
}
