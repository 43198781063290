.date-range-filter__range-picker {
  padding: 8px;
}

.date-range-filter__buttons-container {
  display: flex;
  justify-content: space-between;
  padding: 7px 8px;
  overflow: hidden;
  background-color: inherit;
  border-top: 1px solid rgba(5, 5, 5, 0.06);
}
