.list-templates__wrapper {
  max-width: 360px;
  width: 100%;
  .list-templates {
    min-height: 182px;
    // max-height: 550px;
    max-height: 70vh;
    overflow: auto;
    border: 1px solid rgba(140, 140, 140, 0.35);
    border-radius: 10px;
    padding: 8px 24px;

    .ant-list .ant-list-item {
      padding: 12px 0;
      color: rgba(0, 0, 0, 0.88);
      .template-name {
        color: #131314;
        font-weight: 300;
        overflow: hidden;
        text-overflow: ellipsis;
        // white-space: nowrap;
        a:hover {
          color: #eb3d26;
          text-decoration: none;
        }
      }
    }

    .ant-spin-nested-loading > div > .ant-spin {
      padding-top: 64px;
    }
    .spinner-container {
      min-height: 20px;
      /* display: flex; */
      /* align-items: center; */
      /* justify-content: center; */
    }
  }
}
