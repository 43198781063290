.bulk-visa-application-update__modal {
  .wrapper {
    .exit-container {
      display: none;
    }

    .error-container {
      width: 100%;

      .label-error {
        display: none;
      }
    }

    .modal-container .form-group:has(.error) {
      margin-bottom: 9px;
    }

    .modal-container .form-group {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      margin-bottom: 25px;

      label {
        font-weight: 500;
        max-width: unset;
        width: 40%;
        flex: 0 0 40%;
        padding-left: 0;
      }

      .responsible-select-top {
        width: 60%;
        min-width: 60%;
      }

      .ant-datepicker-wrapper {
        flex: 0 0 60%;
        max-width: 60%;
      }

      .error {
        display: block;
        color: #ff0000;
        flex: 0 0 100%;
        text-align: start;
        padding-left: 40%;
        font-size: 12px;
        line-height: 16px;
      }

      .form-error-container-relocation:has(.error) .responsible-select-top > div:first-of-type {
        border-color: #ff0000;
      }
    }
  }


  .bulk-visa-application-update__content {
    .bulk-visa-application-update__title {
      display: flex;
      align-items: baseline;
      justify-content: space-between;
    }

    img {
      cursor: pointer;
    }

    .bulk-visa-application-update__button-container {
      display: flex;
      justify-content: flex-end;

      .main-btn.btn-form {
        margin: 10px 0 0 24px;
      }
    }


  }
}
