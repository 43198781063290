.contact-employee__section {
  .send-email__wrapper {
    max-width: 800px;
    width: 100%;
    .send-email {
      .html-field .html-field__wrapper {
        height: 100%;
      }
      .ant-select-selection-search {
        padding: 0;
        font-size: 14px;
      }
      .form-group.select-tags__wrapper {
        label {
          padding-top: 8px;
          align-items: normal;
        }
      }
      .form-group {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 24px;

        label,
        .label-message {
          display: flex;
          align-items: center;
          flex: 2;
          max-width: none;
        }

        .control-label {
          flex: 2;
        }

        .form-input-width,
        .html-field__wrapper {
          flex: 8;
          max-width: none;
        }
        .error {
          color: rgb(255, 0, 0);
          width: 100%;
          padding-left: 22%;
          font-size: 12px;
          line-height: 16px;
        }
      }
      .send-email__button-container {
        display: flex;
        justify-content: flex-end;
        .main-btn.btn-form {
          margin: 10px 0 10px 0;
        }
      }
    }
  }
}
