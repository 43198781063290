.relatives-form__container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);

  .form-group {
    display: flex;

    label {
      padding-left: 10px;
    }
  }
}

@media (max-width: 767px) {
  .relatives-form__container {
    grid-template-columns: repeat(2, 1fr);
  }
  .request-employee-documents__container .existing-relatives__wrapper .form-group .control-label,
  .request-employee-documents__container .existing-relatives__wrapper .control-label {
    min-width: 100%;
  }
  .existing-relatives__wrapper {
    padding-bottom: 30px;
  }
}
