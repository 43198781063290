.ModalConfirm {
  .title {
    font-weight: 400;
    font-size: 22px;
    margin-bottom: 1rem;
  }
  .content-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem 0;
    .buttons-container {
      display: flex;
    }
  }

  .wrapper .modal-container {
    padding: 1rem 1rem 2rem;
    margin-top: 0;
  }

  .button {
    width: 7.5rem;
  }
  .wrapper .exit-button img {
    position: unset;
  }
}

@media (min-width: 1500px) and (max-width: 1700px) {
  .ModalConfirm {
    .wrapper {
      .modal-container {
        flex: 0 0 30%;
        max-width: 30%;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .ModalConfirm {
    .wrapper {
      .modal-container {
        flex: 0 0 33%;
        max-width: 33%;
      }
    }
  }
}

@media (max-width: 857px) {
  .ModalConfirm {
    .wrapper .modal-container {
      padding: 1rem;
      top: unset;
    }
  }
}
