.NewRelativeTab {
  margin-bottom: 30px;
  .form-group .control-label {
    max-width: 210px;
  }
  .search-employees .search-form p,
  .search-employees .label-empty {
    max-width: 210px;
    width: 100%;
  }

  .search-employees .search-form p {
    flex: unset;
    padding: 0 15px 0 0;
  }

  .search-employees .error {
    padding-left: 210px;
  }

  .search-employees .search-form .search-form__container,
  .search-employees .search-results__position {
    max-width: 300px;
  }

  .search-employees {
    .label-empty,
    .search-form__label {
      min-width: 12rem;
    }
  }
}

@media (max-width: 767px) {
  .NewRelativeTab {
    .search-employees .search-form,
    .search-employees .search-results__container {
      flex-wrap: wrap;
    }
    .search-employees .search-form p,
    .search-employees .label-empty {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .search-employees .search-form .search-form__container,
    .search-employees .search-results__position {
      flex: 0 0 100%;
      max-width: 100%;
    }
    .search-employees .search-results__wrapper {
      top: 0;
    }
    .search-employees .error {
      padding-left: 0;
    }
  }
}
