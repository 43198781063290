.compensation-form__container {
  .previous-compensation {
    display: flex;
    margin-bottom: 1rem;
    &__label {
      width: 100%;
      max-width: 260px;
      font-weight: 500;
    }
    &__link {
      font-weight: 600;
      cursor: pointer;
    }
  }
}

@media (max-width: 767px) {
  .compensation-form__container {
    .previous-compensation {
      flex-direction: column;
      &__list {
        margin-top: 0.5rem;
      }
    }
  }
}
