@import '../../../../assets/css/variables.scss';

.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 212px;
  background-color: #131314;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 50px 3.75rem;

  .footer-top {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .footer-top__navigation {
      display: flex;

      .contact-us-link {
        display: none;
      }

      &-item {
        margin-right: 40px;
        color: #ffffff;
        font-size: 20px;
        font-weight: 500;

        &:hover {
          border-bottom: 1px solid #FFFFFF;
        }
      }
    }

    .footer-top__contact-us {
      .contact-us-btn {
        padding: 10px 24px;
        border-radius: 4px;
        border: 1px solid #c9c9c9;
        background-color: transparent;
        color: #ffffff;
        font-weight: 500;

        &:hover {
          opacity: .8;
        }
      }
    }
  }

  .footer-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .footer-bottom__email a {
      color: #ffffff;
      font-size: 20px;
      font-weight: 500;
      border-bottom: 1px solid #FFFFFF;

      &:hover {
        opacity: .8;
      }
    }

    .footer-bottom__policy {
      color: #ffffff;
      margin: 0;
    }
  }
}

@media (max-width: 768px) {
  .footer {
    padding: 3vw;

    .footer-top {
      .footer-top__navigation {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 25px;

        .footer-top__navigation-item {
          font-size: 18px;
          text-align: center;
          padding-right: 0;
          margin-right: 0;
        }
      }
    }
  }
}

@media (max-width: 500px) {
  .footer {
    .footer-top {
      .footer-top__navigation {
        margin-top: 10px;
      }
    }
  }
}


@media (max-width: 992px) {
  .footer {
    padding: 3vw;

    .footer-top {
      align-items: center;
      justify-content: unset;
      flex-direction: column;

      .footer-top__need-help {
        margin-top: 60px;
        display: flex;
        justify-content: flex-end;
        width: 100%;
      }

      .contact-us-btn {
        display: none;
      }

      .footer-top__navigation {
        a:last-of-type {
          .footer-top__navigation-item {
            padding-right: 0;
            margin-right: 0;
          }
        }

        &-item {
          font-size: 18px;
          padding-right: 0;
        }

        .contact-us-link {
          display: block;
        }
      }
    }

    .footer-bottom {
      flex-direction: column;

      .footer-bottom__email {
        margin-bottom: 20px;
        text-align: end;

        a {
          font-size: 16px;
        }
      }

      .footer-bottom__policy {
        text-align: center;
        font-size: 14px;
      }
    }
  }
}
