.form-group {
  label.required:after {
    content: '*';
    color: red;
  }

  .control-label {
    max-width: 200px;
    width: 100%;
    padding-right: 15px;
  }

  .form-control {
    display: inline-block;
  }
  .form-control:disabled {
    border: none;
    background: none;
    padding-left: 0px;
    padding-right: 0;
  }

  label {
    font-weight: 500;
  }

  .custom-control-label {
    padding-right: 20px;
  }

  .option-inline {
    display: inline-block;
    margin-right: 24px;
  }

  .custom-checkbox.custom-control {
    padding-left: 0;
  }
  .btn-control {
    padding: 0;
    height: 38px;
    width: 40px;
    margin-left: 3px;
    font-size: 1.2em;
    font-weight: bold;
    color: #495057;
    border: 1px solid #ced4da;
  }

  .form-error-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .form-error-container-relocation {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  .error-container {
    display: flex;
    width: 350px;

    .label-error {
      width: 40%;
      max-width: 40%;
      flex: 0 0 40%;
    }
    .error {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .error {
    padding-left: 15px;
    font-size: 14px;
  }

  textarea::placeholder,
  input::placeholder {
    opacity: 0.6;
  }
}

.SortableItem .custom-checkbox.custom-control {
  padding-left: 25px;
}

.textarea-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  textarea {
    min-height: 100px;
    margin-top: 10px;
    border: 1px solid #ced4da;
  }
}

.react-datepicker__tab-loop {
  display: inline-block;
}

.react-datepicker-popper {
  z-index: 1 !important;
}

.react-datepicker-popper[data-placement^='bottom'] {
  margin-top: 0px !important;
  padding-top: 20px;
}

.react-datepicker-popper[data-placement^='top'] {
  margin-bottom: 0px !important;
  padding-bottom: 20px;
}

.horizontal,
.checkboxes-vertical {
  .custom-checkbox {
    .custom-checkbox__label span {
      margin-right: 10px;
    }
  }
}

.custom-checkbox {
  &.custom-control {
    align-items: center;
    justify-content: center;
  }
  .custom-checkbox__label {
    font-weight: 400;
    display: inline-flex;
    margin: 0;
    cursor: pointer;
    transition: all 0.1s ease;
  }
  .custom-checkbox__label span {
    background-image: url('../../../assets/img/Checkbox_empty.svg');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
    position: relative;
    display: flex;
    align-items: center;
    margin: 0;
    justify-content: center;
    // margin-right: 10px;
    &::after {
      content: '';
      height: 10px;
      width: 10px;
      background-color: transparent;
      transition: all 0.2s ease;
    }
  }

  .custom-control-input:checked + .custom-checkbox__label span {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    background-image: url('../../../assets/img/Checkbox_checked.svg');
  }

  .custom-control-input:disabled + .custom-checkbox__label span {
    opacity: 0.4;
    cursor: not-allowed;
  }
}

/////media

@media (min-width: 768px) {
  .form-group {
    .ant-datepicker-wrapper {
      max-width: 300px;
      width: 100%;
    }
  }
  .form-input-width {
    max-width: 300px;
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .form-group {
    .ant-datepicker-wrapper {
      width: 100%;
    }
  }
  .form-group {
    .form-error-container {
      .form-control {
        margin-left: 0;
      }
    }
    .form-control {
      width: 100%;
      padding-left: 15px;
    }
    .error {
      padding-left: 0;
    }
  }
}

@media (max-width: 767px) {
  .form-group__column {
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 767px) {
  .custom-checkbox {
    &.custom-control {
      padding-left: 15px;
    }
  }
  .form-group .control-label {
    max-width: 100%;
  }
}

.test-x {
    background-image: url('../../../assets/img/buttons/Radio_empty.svg');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    //margin-right: 10px;
}

.test-checked {
    background-image: url('../../../assets/img/buttons/Radio_checked.svg');
    background-position: center;
    background-size: 100%;
    background-repeat: no-repeat;
    height: 18px;
    width: 18px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.disabled{
  opacity: 0.6;
  cursor: not-allowed;
}