$primary: #EB3D26;
$primary-light: #EC2227;
$secondary: #FFFFFF;
$black: #131314;
$dark-grey: #333;
$light-grey: #E5E5E5;
$grey: #C4C4C4;
$dark-grey: #757575;
$red: #EB3D26;
$white: #f4f4f4;

$info: #D6D6D6;
$info-light: #C4C4C4;

// For diagrams:
$Yellow-diagrams: #ffeead;
$Blue-diagrams: #b6e5f6;
$Red-diagrams: #f7b7b7;
$Green-diagrams: #96ceb4;

$primary-font: 'IBM Plex Sans', sans-serif;;
$header-font: 'IBM Plex Serif', serif;;

// For statuses:
$ready-to-relocate: #9325BB;
$accepted: #6BB503;
$new: #FFB200;;
$in-progress: #7073FF;
$on-hold: #7d7d91;
$canceled-by-employee: #A30000;
$rejected: #ff0000;
$duplicate: #7D7D91;
