.employee-documents {
  .no-available {
    padding-left: 2.26rem;
  }
  .checkbox-wrapper {
    margin-top: 20px;
  }
}

.employee-documents__container {
  .select-documents.main-btn.btn-form {
    margin-bottom: 42px;
  }
}

.education_document-container {
  margin-bottom: 30px;
}

@media (max-width: 767px) {
  .employee-documents {
    .main-btn.btn-form.button-notification {
      margin: 0rem 1rem 10px 0;
    }
  }
}
