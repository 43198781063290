.date-field-active {
  .react-datepicker__input-container {
    position: relative;
    width: 100%;

    input {
      padding-right: 35px;
    }
  }
}

.date-field-notify {
  .react-datepicker__input-container {
    position: relative;
    width: 100%;

    input {
      padding-right: 35px;
    }
  }

  .custom-reminder__date-picker {
    .react-datepicker__input-container:before {
      content: unset;
    }
  }
}

.date-field-notify {
  display: flex;
  align-items: baseline;

  .date-picker-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;

    .custom-reminder-button {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;
      height: 38px;
      width: 40px;
      margin-left: 3px;
      font-weight: bold;
      color: #495057;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
    }

    .date-picker {
      display: flex;
    }
  }
}

.date-field {
  .form-group .form-control {
    margin-left: 0;
    padding-left: 0;
  }

  .additional-info {
    vertical-align: top;
    margin-left: 0.2rem;
    fill: #555555;

    &:hover {
      fill: #eb3d26;
      cursor: pointer;
    }
  }

  .select-documents .wrapper .modal-container .date .ant-datepicker-wrapper {
    padding-left: 15px;
  }

  .ant-datepicker-wrapper.ant-picker-disabled {
    border: none;
    background: none;
    padding-left: 0;
    padding-right: 0;
    input {
      color: #495057;
    }
  }

  .ant-datepicker-wrapper {
    width: 100%;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    input {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
    }
  }

  .ant-picker-focused {
    border-color: #eb3d26;
    outline: 0;
    box-shadow: none;
  }
}

@media (max-width: 767px) {
  .date-field-notify {
    display: block;
  }
  .date-field-notify .date-picker-wrapper {
    max-width: 100%;
  }
  .form-group .control-label {
    margin-bottom: 0.5rem;
  }
}
