.search-employees {
  .hide {
    display: none;
  }
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
    cursor: pointer;
  }
  .label-empty {
    flex: 0 0 40%;
  }
  .search-results__wrapper {
    display: flex;
    flex-direction: column;
    position: absolute;
    background-color: #fff;
    border-radius: 4px;
    color: #333;
    width: 100%;
    top: 1rem;
    right: 0;
    border: 1px solid #c4c4c4;
    max-height: 260px;
    z-index: 1;

    .no-content {
      display: flex;
      align-items: center;
      margin: 10px;
    }
  }
  .search-results__wrapper.hide {
    display: none;
  }
  .search-results__items {
    display: block;
    overflow-y: hidden;
    p {
      font-size: 16px;
    }
    &.no-content {
      padding: 8px;
      display: flex;
      font-size: 18px;
      line-height: 24px;
    }

    &:last-child a {
      box-shadow: none;
    }
    li:hover {
      text-decoration: none;
      color: #333;
      background-color: #ececee;
    }
    li p {
      padding: 8px;
      margin: 0;
    }
  }
  .search-results__items:hover {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
    -o-overflow-scrolling: touch;
  }
  .search-results__items::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  .search-results__items::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #c4c4c4;
    box-shadow: 0 0 1px #c4c4c4;
  }
  .search-results__container {
    display: flex;
  }
  .search-results__position {
    position: relative;
    width: 100%;
  }

  .error {
    color: #FF0000;
    flex: 0 0 100%;
    text-align: start;
    padding-left: 40%;
    font-size: 12px;
    line-height: 16px;
  }



  .search-form {
    display: flex;
    align-items: center;
    p {
      font-weight: 500;
      font-size: 16px;
      flex: 0 0 40%;
      padding: 0 15px;
      margin: 0;
    }

    .search-form__container {
      position: relative;
      width: 100%;
    }

    .search-form__container:before {
      content: "";
      position: absolute;
      right: 14px;
      top: 0;
      bottom: 0;
      width: 14px;
      background: url("../../../../assets/img/icons/search.svg") center / contain no-repeat;
    }

    .search-form__input {
      height: calc(1.5em + 0.75rem + 2px);
      padding: 0.375rem 2.5rem 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;
      margin-bottom: 0;
      width: 100%;
    }


    .search-form__input:focus {
      border: 1px solid #FF0000;
      outline: none;
    }
  }
}

.search-employees:has(.error) {
  .search-form .search-form__input {
    border: 1px solid #FF0000;
  }
}

