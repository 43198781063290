.controller-checkbox__container {
  .controller-checkbox {
    border: none;
    background: none;
    padding: 0 8px 0 0;
  }
  label {
    margin-bottom: 14px;
    font-size: 14px;
    cursor: pointer;
  }
}
