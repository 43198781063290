.accommodation-details-container:not(:last-child):after {
  content: '';
  display: block;
  width: 135px;
  height: 3px;
  background-color: #ff0000;
  margin-top: 30px;
  margin-bottom: 30px;
}

.accommodation-details-container {
  .cancel-button {
    display: flex;
    position: unset;
    margin-left: 500px;
    margin-bottom: 10px;
  }

  .delete-form-container {
    display: flex;
    margin-left: 500px;
    margin-bottom: 10px;
  }
}

@media (max-width: 767px) {
  .accommodation-details-container {
    .cancel-button {
      margin-left: 0;
    }

    .delete-form-container {
      margin-left: 0;
    }
  }
}
