.vacAppointment-container {
  .checkbox-with-date-picker {
    display: flex;
    align-items: center;
    .date-picker {
      width: 240px;
    }
    .control-label {
      max-width: 350px;
    }
    .field-error {
      .error-msg {
        color: #eb3d26;
      }
    }
  }
}
