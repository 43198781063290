@import '../../../../assets/css/variables.scss';

.ant-collapse {
  background-color: white;

  .ant-collapse-item-active .ant-collapse-header {
    color: $red;
  }
  .ant-collapse-item .ant-collapse-header {
    padding: 16px 0;
  }

  .ant-collapse-item .ant-collapse-content .ant-collapse-content-box {
    padding: 0 0 16px;
    p {
      margin: 0;
      line-height: 1.5;
    }
  }
}

.collapse-custom {
  .collapse-custom-question {
    font-weight: 500;
    font-size: 18px;
  }
}

.faq-accordion__spinner {
  height: 200px;
  display: flex;
  justify-content: center;
}
