.search-form__container {
  .search-form {
    position: relative;
  }
  .search-form:before {
    content: '';
    position: absolute;
    right: 14px;
    top: 0;
    bottom: 0;
    width: 14px;
    background: url('../../../../assets/img/icons/search.svg') center / contain no-repeat;
  }
  .search-form__input {
    // height: calc(1.5em + 0.75rem + 2px);
    height: 32px;
    padding: 0.375rem 2.5rem 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    margin-bottom: 4px;
    width: 316px;
  }
  .search-form__input:focus {
    border: 1px solid #ff0000;
    outline: none;
  }
}
