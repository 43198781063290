.compensation-form__container {
  .wrapper {
    align-items: flex-start;
  }
  .form {
    padding-bottom: 3rem;
  }
  .compensation__button-container {
    display: flex;
    justify-content: flex-end;
    .compensation_cancel {
      margin-right: 0;
    }
  }
}

.calculate-relocation-compensation {
  .form-group .control-label {
    max-width: 260px;
  }
}

.compensation-form__container {
  .wrapper .modal-container .isSpouseCompanyEmployee__container .form-group {
    display: flex;
    align-items: flex-start;
  }

  .description {
    .form-group {
      align-items: flex-start;
      .label-message {
        display: flex;
        flex-direction: column;
      }
    }
  }
  .isSpouseCompanyEmployee__container {
    position: relative;
    .spouse-company-employees {
      margin-left: 28px;
      position: absolute;
      top: 0;
      left: 260px;
      a {
        font-weight: 500;
        &:hover {
          color: #eb3d26;
          text-decoration: none;
        }
      }
    }
  }
}

.compensation-form__container {
  .wrapper {
    .col-xl-5 {
      flex: unset;
      max-width: 660px;
    }
  }
}

@media (min-width: 768px) {
  .compensation-form__container {
    .currency-form {
      align-items: baseline;
    }
    .form-group .responsible-select-top,
    .wrapper .modal-container .compensationAmount .form-group .text-field__wrapper {
      max-width: unset;
      width: 100%;
    }
    .textarea-group .text-area-form-width {
      max-width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .compensation-form__container {
    .form-group .responsible-select-top,
    .wrapper .modal-container .compensationAmount .form-group .text-field__wrapper {
      max-width: unset;
      width: 100%;
    }
    .textarea-group .text-area-form-width {
      max-width: 100%;
    }
  }
}

@media (max-width: 966px) and (min-width: 767px) {
  .compensation-form__container {
    .isSpouseCompanyEmployee__container {
      .spouse-company-employees {
        top: 2px;
      }
    }
  }
}

@media (max-width: 1035px) and (min-width: 992px) {
  .compensation-form__container {
    .isSpouseCompanyEmployee__container {
      .spouse-company-employees {
        top: 2px;
      }
    }
  }
}

@media (max-width: 767px) {
  .compensation-form__container {
    .wrapper .modal-container .form-group {
      align-items: flex-start;
      input {
        width: 100%;
        max-width: 100%;
        flex: 0 0 100%;
      }
    }
    .wrapper .modal-container .calculate-relocation-compensation .form-group label {
      width: unset;
      max-width: unset;
      flex: unset;
    }
    .radio-horizontal .horizontal {
      padding-left: 0;
    }
    .isSpouseCompanyEmployee__container {
      .spouse-company-employees {
        position: unset;
        padding-left: 0;
      }
    }
    .previous-compensation__label {
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
}
