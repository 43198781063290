.feedback-column {
  font-size: 14px;

  .feedback-title {
    font-weight: 500;
    font-size: 14px;
    padding-bottom: 8px;
    &:after {
      content: '';
      display: block;
      width: 60px;
      height: 1px;
      background-color: #eb3d26;
    }
  }

  .feedback-column__status-container {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;

    .feedback-column__status {
      font-size: 14px;
      line-height: 1.3;
      font-weight: 500;
      margin-right: 4px;
    }

    .dropdown-cell {
      width: 100%;
    }
  }


  .feedback-column__question {
    font-size: 14px;
    margin-bottom: 8px;
  }

  .feedback-column__question-container {
    div,
    span {
      font-size: 14px;
      line-height: 1.3;
    }
    span {
      font-weight: 500;
    }
  }

  .feedback-column__status-container:not(:last-child) {
    border-bottom: 1px solid #ced4da;
    margin-bottom: 8px;
    padding-bottom: 8px;
  }
}

@media (max-width: 992px) {
  .feedback-column {
    .feedback-title {
      &:after {
        content: '';
        display: none;
      }
    }
  }
}
