.response-container-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 3rem 2rem;

  .content-modal {
    font-weight: 500;
    font-size: 18px;
    line-height: 30px;
    margin-bottom: 0;
    text-align: center;
  }

  .spinner {
    margin-top: 1rem;
    img {
      height: 2.5rem;
    }
  }
}

.pending {
  padding: 1rem;
}

@media (max-width: 450px) {
  .response-container-modal {
    padding: 0.5rem 0.5rem 1rem;
  }
}
