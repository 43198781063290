.general-documents-messages__container {
  .general-documents__container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 10px;
  }
  .expirationBottomBorder {
    border-bottom: 1px solid #ced4da;
    margin-bottom: 2px;
    padding-bottom: 2px;
  }
  .validation-message {
    color: #eb3d26;
  }
}
