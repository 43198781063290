.case-excel-title__container {
  display: flex;
  align-items: center;
  gap: 10px;
  font-weight: 500;
  font-size: 15px;
  color: #7a7a7a;

  .all-excel-btn {
    cursor: pointer;
    display: flex;
    justify-content: center;
    border: 1px solid #eb3d26;
    border-start-start-radius: 6px;
    border-end-start-radius: 6px;
    border-inline-end-width: 1px;
    border-start-end-radius: 6px;
    border-end-end-radius: 6px;
    padding: 4px 12px;
    &:hover {
      transition: opacity 0.3s;
    }
  }
  .allExcelBtnActive {
    background-color: #eb3d26;
    color: white;
  }
}
