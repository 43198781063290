.responsible-select-top {
  display: inline-block;
}

@media (min-width: 768px) {
  .form-group {
    .responsible-select-top {
      min-width: 300px;
    }
  }
}

@media screen and (max-width: 767px) {
  .responsible-select-top {
    width: 100%;
  }
}
