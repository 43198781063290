@import './variables.scss';

* {
  font-family: $primary-font;
  font-size: 16px;
  font-weight: 400;
  line-height: 19.6px;
  box-sizing: border-box;
}

::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: white;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(122, 122, 122, 0.3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(101, 101, 101);
}

/* Ant design scrollbar */
.rc-virtual-list-scrollbar {
  width: 4px !important;
  right: -4px !important;
  .rc-virtual-list-scrollbar-thumb {
    background: rgb(122, 122, 122, 0.3) !important;
    width: 4px !important;
    border-radius: unset !important;
  }
}

.statistic__section .apexcharts-canvas .apexcharts-toolbar {
  z-index: 1;
}

.ant-picker-focused {
  border-color: #eb3d26;
  box-shadow: none;
}

.anticon-close-circle {
  svg {
    fill: #eb3d26;

    &:hover {
      fill: #c73b2b;
    }
  }
}

.Snackbar_snackbar__text__1Hx2a {
  font-size: 18px;
  font-weight: 700;
  font-family: $primary-font;
}

p {
  strong * {
    font-weight: 700;
  }
}

.description {
  strong * {
    font-weight: 700;
  }
}

em {
  font-style: italic;
}
u {
  text-decoration: underline;
}
s {
  text-decoration: line-through;
}

.form-control:focus {
  border-color: #eb3d26;
  outline: 0;
  box-shadow: none;
}

.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  padding: 0;
  overflow: hidden;
  border: 0;
  clip: rect(0 0 0 0);
}

section {
  padding: 3.75rem;
}

h3 {
  margin: 0;
  margin-bottom: 30px;
  font-size: 2em;
  font-weight: 300;
  letter-spacing: 0.1px;
  line-height: 32px;
}

h4,
.h4 {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 400;
}

.blur {
  -webkit-filter: blur(2px);
  -moz-filter: blur(2px);
  -o-filter: blur(2px);
  -ms-filter: blur(2px);
  filter: blur(2px);
}

a:hover {
  text-decoration: none;
}

a,
.link {
  color: $black;
}

a,
.link:visited {
  color: $black;
}
.title-location {
  .underlined {
    margin-bottom: 20px;
  }
}

.underlined::after {
  content: '';
  display: block;
  width: 135px;
  height: 2px;
  background-color: $red;
  margin-top: 5px;
}

.no-data {
  width: fit-content;
  margin: 0 auto;
  margin-top: 10px;
  margin-bottom: 20px;

  font-size: 18px;
}

// buttons

.button {
  color: $white;
  font-size: 1em;
  min-height: 40px;
  cursor: pointer;
  font-weight: 500;
  padding: 2px 40px;
}

.button:hover,
.button:focus {
  border: 1px solid $red;
  outline: none;
}

.main-btn {
  transition: opacity 0.3s ease-in-out;
  position: relative;
  font-size: 18px;
  background: #fff;
  padding: 8px 28px 8px;
  border-radius: 4px;
  z-index: 0;
  border: 1px solid $primary;
  overflow: hidden;
  color: $primary;
}
.main-btn.btn-back {
  background: #eb3d26;
  color: #ffffff;
}
.main-btn.btn-form {
  margin: 10px 20px 10px 0;
}

.main-btn:hover:not(.btn-disabled, :disabled) {
  color: #ffffff;
  background: #eb3d26;
}

.main-btn:not(.btn-back, .btn-disabled, :disabled):hover::before {
  opacity: 0;
}

.main-btn::before {
  content: '';
  position: absolute;
  background: black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  transition: 0.2s;
}
.main-btn:hover::before {
  opacity: 0.15;
}

.info-btn {
  transition: opacity 0.3s ease-in-out;
  position: relative;
  color: #f4f4f4;
  font-size: 18px;
  background: $info;
  padding: 8px 28px 8px;
  border-radius: 5px;
  z-index: 0;
  overflow: hidden;
  color: $black;
}

.info-btn:hover:not(.btn-disabled) {
  color: $black;
  background: $info-light;
  opacity: 0.9;
}
.info-btn::before {
  content: '';
  position: absolute;
  background: black;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  opacity: 0;
  transition: 0.2s;
}
.info-btn:hover::before {
  opacity: 0.15;
}

.button-filter {
  min-height: 30px;
  padding: 0 18px;
  background: transparent;

  font-weight: bold;
  line-height: 140%;
}

.button-filter:focus,
.button-filter:hover {
  background: $red;
  border: 1px solid $white;
}

.button-filter:active {
  background: $red;
  outline: none;
  border: 2px solid transparent;
}

.btn-disabled {
  opacity: 0.3;
}

.red {
  background: linear-gradient(to right, #f41351, #eb1c23);
  color: #fff;
}

.white {
  border: 2px solid $grey;
  color: $black;
}

.black {
  background-color: $black;
}

.transparent {
  border: 1px solid $white;
}

.block,
.document {
  margin-bottom: 30px;
}

.request-top {
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 75px;
  padding-top: 10px;
  width: 100%;
  z-index: 2;
  background: #fff;
  border-bottom: 1px solid #c9c9c9;
  min-height: 84px;
  // box-shadow: 0px -4px 5px -5px rgba(34, 60, 80, 0.6) inset;
  padding-bottom: 10px;
  align-items: center;
  flex-wrap: wrap;
  h3 {
    margin-bottom: 4px;
  }
  .button {
    margin-bottom: 10px;
  }
}

.btn-grey {
  width: 17rem;
  height: 3.5rem;
  font-weight: 600;
  font-size: 18px;
  color: $black;
  background-color: $info;
  border-radius: 0;
  border: 0;
  padding: 0.3rem 1rem;
  font-family: $primary-font;
}

.btn-grey:hover,
.btn-grey:focus {
  background-color: $info-light;
  outline: none;
  border: 0;
}

.updated-at-by-container {
  width: 100%;
}
.updated-at-by {
  font-weight: 500;
  float: right;
  color: $grey;
}

.dashboard-item {
  padding-left: 15px;
  padding-right: 15px;
}

@media (max-width: 992px) {
  h3 {
    font-size: 26px;
  }
  .underlined::after {
    width: 100px;
    margin-top: 0;
  }
  .main-btn {
    min-height: 40px;
    padding: 8px 16px;
  }
  .request-top {
    top: 70px;
  }
  .app-wrapper {
    padding-top: 70px;
  }
}
@media (max-width: 767px) {
  .Request .button {
    min-width: 180px;
  }
}
.smg-link {
  margin-left: 5px;
}
h3 .smg-link {
  margin-left: 5px;
  border-bottom: 3px solid transparent;
  font-size: 1em;
  font-weight: bold;
  letter-spacing: 0.1px;
  line-height: 32px;
  color: $black;
}
h3 .smg-link:hover {
  color: #333;
  border-bottom: 3px solid #ff0000;
  transition: all 0.2s ease;
  text-decoration: none;
}

button:focus {
  outline: none;
}

@media (max-width: 767px) {
  .responsible-top {
    width: 100%;
  }
}

.rdw-editor-toolbar {
  display: none;
}

@media screen and (max-width: 767px) {
  .react-form-builder {
    .react-form-builder-toolbar {
      width: 100%;
      background: #fff;
      margin-top: 0px;
      position: unset;
      z-index: 900;
      bottom: 0px;
      transition: transform 0.5s;
      transform: translate(0, 0);
      right: 0;
      border-top: 0 solid #ccc;
    }
  }
}

@media (max-width: 767px) {
  h3 {
    font-size: 23px;
  }
}

@media (max-width: 992px) {
  section {
    padding: 3vw;
  }
}

.Toastify {
  a {
    font-weight: 700;
  }
  a:hover {
    color: #fb4844;
  }
}

.ant-table-cell .view-details {
  text-align: end;
  a {
    border-bottom: 1px solid #eb3d26;
  }

  &:hover {
    opacity: 0.8;
  }
}