@import "../../../assets/css/variables.scss";

.back-to-main {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  position: fixed;
  top: 9.5625rem;
  left: 5.94rem;
  color: #9b9b9b;
  cursor: pointer;

  .text {
    margin: 0;
    padding-left: 4px;
    //font-weight: 400;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (max-width: 1200px) {
  .back-to-main {
    top: 9.5625rem;
    left: 2rem;
  }
}

@media (max-width: 992px) {
  .back-to-main {
    position: unset;
    padding: 0;
    padding-bottom: 0.5rem;
  }
}
