@import '../../../../assets/css/variables';

.btn-quick-nav {
  width: 44px;
  height: 44px;
  position: fixed;
  bottom: 68px;
  z-index: 99;
  right: calc((100vw - 1620px) / 4);
  font-size: 0;
  border-radius: 100%;
  background: $primary url(../../../../assets/img/buttons/quick_nav.svg) center/37.5% auto no-repeat;
  border: none;
}

.btn-quick-nav-display {
  transition: all 0.5s ease-out;
  transform: none;
  pointer-events: visible;
  opacity: 1;
}

.btn-quick-nav-hidden {
  transition: all 0.5s ease-out;
  transform: translateX(50px);
  opacity: 0;
  pointer-events: none;
}

@media (max-width: 1620px) {
  .btn-quick-nav {
    right: 3.75rem;
  }
}

@media (max-width: 992px) {
  .btn-quick-nav {
    right: 3vw;
  }
}
