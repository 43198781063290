.statistics__generation-excel-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 170px;
}

.statistics__generation-excel-btn.main-btn.btn-back {
  margin: 0;
  margin-bottom: 0.5rem;
}

.statistics__generation-excel-btn.button {
  min-height: 40px;
  height: 40px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  border: 1px solid #eb3d26;
  border-start-start-radius: 6px;
  border-end-start-radius: 6px;
  border-inline-end-width: 1px;
  border-start-end-radius: 6px;
  border-end-end-radius: 6px;
  margin-bottom: 4px;
}
