@import '../../../../assets/css/variables.scss';

.country-info__container {
  position: relative;
  padding: 24px;
  outline: 1px solid #d9d9d9;
  .country-info__header {
    display: flex;
    align-items: center;
    .country-info__header-country-name {
      font-weight: 700;
      font-size: 24px;
      margin-right: 24px;
    }
    img {
      width: 50px;
      height: 30px;
    }
  }
  .country-info__content {
    margin: 0;
    padding-top: 26px;
    line-height: 20px;
    padding-bottom: 64px;

    .pretty-anchor,
    a {
      color: $red;
      border-bottom: 1px solid $red;
    }

    br {
      display: block;
      margin: 8px 0;
      content: '';
    }
  }
  .country-info__relocation-package {
    position: absolute;
    bottom: 24px;
    .main-btn.btn-form {
      margin: 0;
      a {
        color: white;
        font-size: 18px;
        font-weight: 500;
      }
    }
    .disabled {
      background: #c4c4c4;
      border: 1px solid #c4c4c4;
    }
  }
}

@media (max-width: 544px) {
  .country-info__container {
    padding: 16px;
  }
}
