@import '../../../assets/css/variables.scss';

.display-documents__container {
  .display-documents {
    display: grid;
    column-gap: 0.25rem;

    .doc-type {
      width: 135px;
    }

    .exp-date {
      white-space: nowrap;
    }

    .exp-date.expirationInMonth {
      color: $red;
    }
    .exp-date.expirationInMonth.visaDocumentResidencePermitReason {
      color: black;
    }

    .exp-date.expirationInMonth.temporaryPermitResidence {
      color: black;
    }

    .exp-date.expirationBottomBorder {
      border-bottom: 1px solid #ced4da;
      margin-bottom: 2px;
      padding-bottom: 2px;
    }
  }
}

.display-documents__container {
  padding-left: 4px;

  .validation-messages {
    color: $red;
  }
}

.display-documents-column__container {
  .exp-date {
    white-space: nowrap;
  }

  .exp-date.expirationInMonth {
    color: $red;
    white-space: nowrap;
  }

  .exp-date.expirationBottomBorder {
    border-bottom: 1px solid #ced4da;
    margin-bottom: 2px;
    padding-bottom: 2px;
  }
}
