.event {
  font-size: 13px;
  font-weight: 600;
}

.not-viewed-event:after {
  content: ' \25CF';
  font-size: 13px;
  color: #eb3d26;
}

.CaseItem__name,
.case-list,
.legalization-documents__table {
  .event-icon__wrapper {
    cursor: pointer;
  }
}

.EmployeeName__wrapper {
  .event-icon__wrapper {
    cursor: pointer;
    margin-left: 12px;
    width: 22px;
    min-height: 22px;
  }
}

.CaseItem__name {
  .event-icon__wrapper {
    cursor: pointer;
    margin-left: 12px;
    width: 22px;
    min-height: 22px;
  }

  .event-tooltip {
    font-size: 16px;
  }
}

@media (min-width: 1366px) {
  .event-tooltip {
    width: max-content;
  }
}
