.menu-list {
  &__item {
    cursor: pointer;
    padding: 10px 0 10px 24px;
    font-weight: 500;
    &-active {
      background-color: #fdecea;
      color: #eb3d26;
      font-weight: 700;
    }
    &:hover {
      background-color: #f5d8d4;
    }
  }
  margin-bottom: 10px;
}

@media screen and (max-width: 767px) {
  .menu-list {
    margin-bottom: 0;
    width: 100%;
    padding: 0;
    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-operations {
      display: none;
    }
    .ant-tabs-top > .ant-tabs-nav {
      margin: 0 0 10px 0;
    }
  }
}
