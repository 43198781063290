@import '../../../../../assets/css/variables.scss';

.multiple-filter__checkbox-group {
  .ant-checkbox-wrapper {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    padding: 5px 12px;
    border-radius: 4px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .ant-checkbox + span {
    font-size: 14px;
  }
  .ant-checkbox-wrapper-checked {
    background-color: #fff4f0;
    &:hover {
      background-color: #ffd9cc;
    }
  }
  .ant-popover-inner {
    padding: 0;
    min-width: 280px;
  }
  .ant-popover-arrow {
    display: none;
  }
  .multiple-filter__checkbox-menu.ant-checkbox-group {
    display: flex;
    flex-direction: column;
  }
}

.multiple-filter__checkbox-group {
  .multiple-filter__checkbox-group-buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(5, 5, 5, 0.06);
    border-bottom: 1px solid rgba(5, 5, 5, 0.06);
    padding: 7px 10px;
    .reset-btn {
      cursor: pointer;
      font-size: 14px;
      color: $red;
    }
    .ant-btn {
      font-size: 14px;
      height: 24px;
      padding: 0px 7px;
      border-radius: 4px;
    }
  }
}
