.employee-select-documents {
  .no-available-forms {
    font-size: 14px;
  }

  .employee-select-documents__forms-container {
    margin-bottom: 30px;

    .no-available-forms {
      font-size: 14px;
    }

    .select-documents__title {
      font-weight: 500;
      font-size: 16px;
    }
  }

  .employee-select-documents__button-container {
  display: flex;
  justify-content: flex-end;

  .main-btn.btn-form {
    margin-left: 1rem;
  }
}
}


