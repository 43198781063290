.country-filter.ant-select {
  &:hover {
    cursor: pointer;

    .ant-select-selector {
      border-color: #d9d9d9;
    }
  }

  .ant-select-selection-item {
    font-size: 14px;
  }
}

.ant-select-item-option-content {
  font-size: 14px;
}

.country-filter.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover
.ant-select-selector {
  border-color: #d9d9d9;
  background-color: #c9c9c91a;
  color: rgba(0, 0, 0, 0.88);
}

.ant-select-selection-search {
  padding: 0 18px;
  font-size: 14px;
}

.prefix-icon-wrapper {
  position: absolute;
  z-index: 1;
  width: 2rem;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item {
  padding-inline-start: 18px;
}