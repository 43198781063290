.case-progress__block {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  label {
    margin-bottom: 0;
  }
  .controller-checkbox__container label {
    margin-bottom: 0;
  }
}
