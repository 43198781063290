.send-info-modal {
  &__question {
    font-weight: 500;
    padding: 24px 0 0;
    margin: 0;
  }
  .custom-checkbox .custom-checkbox__label span {
    margin-right: 0;
  }
  .main-btn.btn-form {
    margin-bottom: 0;
  }
}

.send-info {
  position: sticky;
  top: 90vh;

  // shake animation
  .send-docs__btn {
    animation: shake-animation 2s ease infinite;
    transform-origin: 50% 50%;
    animation-delay: 10s;
    animation-duration: 20s;
  }

  @keyframes shake-animation {
    0% {
      transform: translate(0, 0);
    }
    1.78571% {
      transform: translate(5px, 0);
    }
    3.57143% {
      transform: translate(0, 0);
    }
    5.35714% {
      transform: translate(5px, 0);
    }
    7.14286% {
      transform: translate(0, 0);
    }
    8.92857% {
      transform: translate(5px, 0);
    }
    10.71429% {
      transform: translate(0, 0);
    }
    100% {
      transform: translate(0, 0);
    }
  }

  //// Ring border Animation
  .send-docs__btn::after {
    content: '';
    width: 40px;
    height: 40px;
    opacity: 0;
    border-radius: 100%;
    border: 4px solid #eed6de;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 2s ease infinite;
    animation-delay: 10s;
    animation-duration: 20s;
  }

  @keyframes ring {
    0% {
      width: 40px;
      height: 40px;
      opacity: 1;
    }
    15% {
      width: 300px;
      height: 300px;
      opacity: 0;
    }
    100% {
      width: 300px;
      height: 300px;
      opacity: 0;
    }
  }
  ///
}

.send-info__tooltip {
  font-size: 13px;
  font-weight: 600;
}

.send-docs-success {
  .wrapper .modal-container {
    max-width: 480px;
    min-height: 260px;
  }
  .modal-message {
    p {
      margin-top: 15px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
    }
    .main-btn.btn-form {
      margin: 0;
    }

    .ok-btn {
      // width: 70px;
      margin: 0;
      position: absolute;
      bottom: 40px;
      right: 40px;
    }

    // margin-top: 10px;
  }
}

.send-info__modal {
  .wrapper .modal-container .form-group label {
    max-width: unset;
    width: unset;
    flex: unset;
    font-weight: 400;
  }
  .wrapper .modal-container {
    max-width: 480px;
    min-height: 500px;
  }

  .form-group .option-inline {
    margin-right: 0;
  }

  .wrapper .modal-container .form-group {
    align-items: flex-start;
  }
}

@media (max-width: 857px) {
  .send-docs-success {
    .modal-message {
      .ok-btn {
        bottom: 16px;
        right: 16px;
      }
    }
  }
}
