.notes {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  &-buttons {
    margin-top: 1rem;
  }

  &:hover {
    cursor: pointer;
  }
  .notes-img {
    height: 1.8rem;
  }
}

.note-container {
  width: 100%;
  position: relative;
  .note {
    display: flex;
    flex-direction: column;
    width: 100%;
    &-cancel__container {
      display: flex;
    }
    p {
      margin: 15px 0 0 0;
    }
  }
  .cancel-button {
    margin-top: 10px;
    margin-left: 1rem;
    position: unset;
  }
}

.textarea-group {
  .tooltip-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;

    img {
      margin-bottom: 4px;
    }
  }
}

@media (min-width: 768px) {
  .note-container {
    .note-field-textarea {
      max-width: 500px;
    }
  }
}

@media (max-width: 767px) {
  .note-container {
    .cancel-button {
      left: unset;
      right: 0;
      top: 11px;
    }
  }
}
