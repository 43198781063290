.employee-vac__container {
  padding-bottom: 50px;
  padding-top: 15px;

  .help-visa__container {
    .form-group .control-label {
      max-width: 220px;
    }
  }
}
