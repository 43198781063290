.document-form__title-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 30px;

  .document-form__accordion {
    width: 24px;
  }
  .accordionOpen {
    transform: rotateX(180deg) rotateY(0);
    transition: all 0.4s ease;
  }
  .accordionClose {
    transform: rotate(0);
    transition: all 0.4s ease;
  }
  .document-form__header {
    max-width: 100%;
    margin-bottom: 0;
  }

  .icon {
    height: 1.8rem;
    margin-bottom: 30px;
  }
}
