.custom-reminder__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 0.3rem;

  .custom-reminder__label {
    font-weight: 500;
    margin-bottom: unset;
  }

  .custom-reminder__date-picker {
    max-width: 150px;
  }
}