@import '../../../../assets/css/variables.scss';

body {
  min-width: 320px;
}

.header {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  background: $secondary;
  color: $black;
  padding: 25px 3.75rem;
  max-height: 75px;
  width: 100%;
  z-index: 6;
  border-bottom: 1px solid $grey;
  gap: 30px;

  .settings-modal-icon:hover {
    cursor: pointer;
  }

  .header-inner {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .left-menu {
    display: flex;
    align-items: center;
  }

  .right-menu {
    display: flex;
    align-items: center;

    .logout {
      cursor: pointer;
      margin-left: 2.5rem;
    }
  }

  .avatar-root {
    width: 40px;
    height: 40px;
    display: flex;
    overflow: hidden;
    position: relative;
    font-size: 1.25rem;
    align-items: center;
    flex-shrink: 0;
    font-family: "Lato", sans-serif;
    line-height: 1;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    cursor: pointer;

    img {
      color: transparent;
      width: 100%;
      height: 100%;
      object-fit: cover;
      text-align: center;
      text-indent: 10000px;
    }
  }

  .new-menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 28px;
  }

  .menu {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: 30px;

    label {
      margin-bottom: 10px;
    }

    .menu-btn {
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      z-index: 1;
      width: 30px;
      height: 30px;
    }

    .hamburger_menu {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      opacity: 1;
      transition: opacity 0.01s linear;
      transition-delay: 0.2s;
    }

    div {
      width: 100%;
      height: 3px;
      background: $black;
      margin-bottom: 8px;
      transform: translateY(0);
      transition: transform 0.15s linear;
      transition-delay: 0.25s;
    }

    div:last-child {
      margin-bottom: 0;
    }

    .menu-btn > span::before,
    .menu-btn > span::after {
      display: block;
      position: absolute;
      width: 100%;
      height: 9px;
      // background-color: $white;
    }

    .menu-btn > span::before {
      content: '';
      top: 16px;
    }

    .menu-btn > span::after {
      content: '';
      top: 36px;
    }
  }
}

.menu-wrapper {
  display: flex;
  align-items: center;
  margin-left: auto;
  padding-left: 10px;
  justify-content: space-between;
  gap: 30px;

  a.desctop {
    text-decoration: none;
    display: flex;
    color: $black;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0;
  }

  a.active {
    color: #eb3d26;
  }
}

@media (min-width: 769px) and (max-width: 860px) {
  .header {
    .menu-wrapper a.desctop {
      font-size: 18px;
      margin-left: 14px;
    }
  }
}

@media (max-width: 992px) {
  .header {
    min-height: 70px;
    padding: 16px 3vw;

    .menu-wrapper a.desctop {
      font-size: 18px;
    }
  }
}

@media (min-width: 769px) {
  header .right-menu .mobile-search .HeaderSearch {
    display: none;
  }
}

@media (max-width: 768px) {
  header .left-menu .menu-wrapper a.desctop,
  header .right-menu .HeaderSearch {
    display: none;
  }
  header .right-menu .mobile-search .HeaderSearch {
    display: flex;
  }
}

@media (max-width: 576px) {
  .header {
    .rotate {
      transform: rotate(90deg);
      -webkit-transition: width 1s, height 1s, background-color 1s, -webkit-transform 1s;
      transition: width 1s, height 1s, background-color 1s, transform 1s;
    }

    .menu-btn {
      -webkit-transition: width 1s, height 1s, background-color 1s, -webkit-transform 1s;
      transition: width 1s, height 1s, background-color 1s, transform 1s;
    }
  }
}

@media (max-width: 375px) {
  .header {
    padding: 16px 1rem;
  }
}
