@import "./variables.scss";

body {
  margin: 0;
  padding: 0;
  //overflow: hidden;
  font-family: $primary-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  min-height: 100vh;

  .heading, h1, h2, h3, h4 {
    font-family: $header-font;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}