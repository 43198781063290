.question-block {
  $marginTop: -12px;
  $marginBottom: 10px;

  .description-item {
    padding: 0 20px 14px 20px;
  }

  .question-name {
    padding: 14px 20px 0 20px;
  }

  .question-answer {
    // radio group validation
    .custom-radio .radios-error-container:has(.error) {
      align-items: flex-start;
      flex-direction: column;
      .error {
        font-size: 12px;
        order: -1;
        margin-bottom: $marginBottom;
        margin-top: $marginTop;
      }
    }
    //checkbox group validation

    .radio-horizontal:has(.checkboxes-vertical):has(.error),
    .radio-horizontal:has(.horizontal):has(.error) {
      flex-direction: column;
      .control-label {
        order: 1;
      }
      .error {
        order: 2;
      }
      .checkboxes-vertical,
      .horizontal {
        order: 3;
      }
      .error {
        padding-left: 0;
        font-size: 12px;
        margin-top: $marginTop;
        margin-bottom: $marginBottom;
      }
    }

    // textarea validation
    .textarea-group {
      .label-message:has(.error-textarea) {
        display: flex;
        flex-direction: column;
      }
      .error-textarea {
        font-size: 12px;
        margin-top: $marginTop;
        margin-bottom: $marginBottom;
      }
    }

    //input validation

    .form-group:has(.text-field__wrapper):has(.error) {
      display: flex;
      flex-direction: column;
      .control-label {
        order: 1;
      }
      .text-field__wrapper {
        order: 3;
      }

      .error {
        font-size: 12px;
        color: rgb(255, 0, 0);
        order: 2;
        margin-top: $marginTop;
        margin-bottom: $marginBottom;
        padding-left: 0;
      }
    }

    // dropdown validation

    .form-group:has(.responsible-select-top):has(.error) {
      display: flex;
      flex-direction: column;
      .control-label {
        order: 1;
      }
      .responsible-select-top {
        order: 3;
      }

      .error {
        font-size: 12px;
        color: rgb(255, 0, 0);
        order: 2;
        margin-top: $marginTop;
        margin-bottom: $marginBottom;
        padding-left: 0;
      }
    }

    //datefield validation

    .date-field:has(.error) {
      display: flex;
      flex-direction: column;
      .control-label {
        order: 1;
      }
      .ant-picker {
        order: 3;
      }

      .error {
        font-size: 12px;
        color: rgb(255, 0, 0);
        order: 2;
        margin-top: $marginTop;
        margin-bottom: $marginBottom;
        padding-left: 0;
      }
    }
  }
}
