.checkbox-group-with-date-picker:last-child {
  margin-bottom: 0;
}

.checkboxes-vertical.checkboxes-with-date-picker__wrapper .custom-checkbox .custom-checkbox__label span,
.horizontal .custom-checkbox .custom-checkbox__label span {
  margin-top: 2px;
}

.checkboxes-with-date-picker__wrapper {
  width: 100%;
}

.checkbox-group-with-date-picker {
  width: 100%;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-bottom: 1rem;
  .custom_checkbox__limited-text {
    margin: 0;
    word-break: break-word;
  }
  .customm-checkbox {
    word-break: break-all;
  }
  .custom-checkbox {
    .custom-checkbox__label span {
      &::after {
        content: '';
        height: 20px;
        width: 20px;
      }
    }
  }
}

.checkboxgroup__dates {
  align-items: baseline;
}

.form-group {
  .checkbox-group-with-date-picker {
    .ant-datepicker-wrapper {
      max-width: 140px;
      min-width: 140px;
    }
  }
}

@media (min-width: 768px) {
  .checkbox-group-with-date-picker {
    max-width: 550px;
    width: 100%;
  }
  .checkboxes-with-date-picker__wrapper {
    max-width: 550px;
    width: 100%;
  }
  .form-group {
    .checkbox-group-with-date-picker {
      .form-control {
        margin-left: 0;
        margin-top: 4px;
      }
    }
  }
}

@media (max-width: 767px) {
  .form-group {
    .checkbox-group-with-date-picker {
      .form-control {
        margin-left: 0;
        margin-top: 4px;
      }
      .ant-datepicker-wrapper {
        padding: 0;
      }
    }
  }
}
