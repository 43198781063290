.visa-details__checkbox-group {
  padding-left: 200px;
  margin-top: 14px;
}

@media (max-width: 767px) {
  .visa-details__checkbox-group {
    padding-left: 0;
  }
}
