@import '../../../../src/assets/css/variables.scss';

.asked-question {
  .heading {
    font-size: 40px;
    font-weight: 700;
    padding-left: 0;
    margin-bottom: 0;
  }

  h1 {
    font-size: 40px;
    margin-bottom: 0.5rem;
    font-weight: 300;
    line-height: 1.2;
    &::after {
      content: '';
      display: block;
      width: 70px;
      height: 2px;
      background-color: $red;
    }
  }

  .accordion {
    .ant-collapse > .ant-collapse-item {
      overflow: hidden;
    }

    margin-bottom: 5rem;
    .faq-item {
      padding: 1.5rem 0 1rem;
      border-bottom-width: 2px;
      border-bottom-color: #9d9e9d;
      border-bottom-style: solid;
    }
    a {
      color: #3b66b0;
      text-decoration: none;
      font-family: $primary-font;
      font-weight: 400;
      line-height: 1.5;
      font-size: 1.125rem;
    }
  }

  .question {
    padding-right: 5%;
  }

  .accordion__button {
    background-image: url('../../../assets/img/buttons/Plus.png');
    min-height: 2rem;
    background-size: 1rem;
    background-repeat: no-repeat;
    background-position: right center;

    outline: none;
    margin-bottom: 0.5rem;
    font-family: $primary-font;
    font-weight: 700;
    line-height: 1.2;
    font-size: 1.125rem;
    cursor: pointer;
    padding-right: 3rem;
    width: 100%;
    transition: all 0.4s ease;
  }

  .accordion__minus {
    background-image: url('../../../assets/img/buttons/Minus.png');
  }

  .accordion__panel {
    animation: fadein 0.35s ease-in;
    color: black;
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5;
    font-family: $primary-font;
    overflow: auto;
    transition: max-height 0.6s ease;
  }

  .accordion__button[aria-expanded='true']::before,
  .accordion__button[aria-selected='true']::before {
    transform: rotate(45deg);
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.asked-question .tabs {
  display: flex;
  flex-wrap: wrap;
  margin: 50px 0;

  .link:hover {
    color: black;
  }
}
.asked-question .tabs-wrapper .textarea-group {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

.asked-question .tab-item {
  background: none;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 2rem 2rem 2rem 0;
  width: auto;
  transition: 0.3s;
  font-family: $header-font;
  font-size: 1.875rem;
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: left;
  font-weight: 300;
  line-height: 0;

  &.active {
    color: $red;
    font-weight: bold;
  }
}

.accordion__content {
  overflow: hidden;
  transition: max-height 0.6s ease;
}

@media (max-width: 576px) {
  .asked-question {
    .heading {
      display: none;
    }
    .tabs {
      margin: 0;
      display: flex;
      flex-direction: column;
      .accordion {
        .faq-item:first-child {
          padding-top: 0;
        }
      }
    }
    .accordion_sm {
      margin-bottom: 2rem;
      width: 100%;
    }
    .link:first-child {
      margin-top: 1.5rem;
    }
    .link:last-child {
      margin-bottom: 2rem;
    }
    .tab-item {
      width: auto;
      margin-bottom: 0.1rem;
    }

    h1 {
      font-size: 35px;
    }
  }
}
