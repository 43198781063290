.image-gride {
  padding: 12px 10px 0 16px;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  margin: auto;
  min-height: 112px;
  display: flex;
  flex-wrap: wrap;

  .add-new-file {
    height: 24px;
    width: 24px;
  }
  .add-new-file__container {
    cursor: pointer;
  }
}

.image-gride__display-attachment {
  position: relative;
  display: inline-block;
  text-align: center;
  margin-right: 8px;
  margin-bottom: 12px;

  span {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 10px;
    width: 60px;
    padding: 0 6px;
    color: #666666;
  }

  .attachment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 60px;
    height: 60px;
    border: 1px solid #d9d9d9;
  }

  .close-attachment {
    width: 8px;
    height: 8px;
    cursor: pointer;
  }

  .delete-attachment {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 18px;
    height: 18px;
    background-color: white;
    box-shadow: 0px 0px 13px rgb(0 0 0 / 5%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .display-attachment__icon {
    height: 24px;
    width: 18px;
    margin-bottom: 4px;
  }
}
