.vac-warning-modal {
  .wrapper .modal-container {
    max-width: 400px;
    min-height: 260px;
  }
  .modal-message {
    p {
      margin-top: 6px;
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
    }
    .main-btn.btn-form {
      margin: 0;
    }

    .ok-btn {
      margin: 0;
      position: absolute;
      bottom: 40px;
      right: 40px;
    }
  }
}

@media (max-width: 857px) {
  .vac-warning-modal {
    .modal-message {
      .ok-btn {
        bottom: 16px;
        right: 16px;
      }
    }
  }
}
