.tox:not(.tox-tinymce-inline) .tox-editor-header {
  padding: 0;
  border: none;
}

.tox .tox-tbtn {
  margin-left: 2px;
  margin-right: 2px;
}

.tox-toolbar__group {
  border-top: 1px solid #d9d9d9;
}

.tox:not([dir='rtl']) .tox-toolbar__group {
  &:last-of-type {
    border-right: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
  }

  &:not(:last-of-type) {
    position: relative;
    border-right: none;
    border-top: 1px solid #d9d9d9;

    &:after {
      content: '';
      position: absolute;
      border-left: 1px #666666 solid;
      top: 30%;
      right: 0;
      height: 40%;
      margin-top: auto;
      margin-bottom: auto;
    }
  }
}

.tox .tox-tbtn.tox-tbtn--enabled svg {
  fill: #ffffff;
}

.tox .tox-tbtn.tox-tbtn--disabled:hover svg {
  fill: #eb3d26;
}

.tox-tinymce:not(.tox-tinymce-inline) .tox-editor-header:not(:first-child) .tox-toolbar:first-child,
.tox-tinymce:not(.tox-tinymce-inline)
  .tox-editor-header:not(:first-child)
  .tox-toolbar-overlord:first-child
  .tox-toolbar__primary {
  border: none;
}

.tox .tox-toolbar,
.tox .tox-toolbar__overflow,
.tox .tox-toolbar__primary {
  background-image: none;
}

.html-field {
  .html-field__wrapper {
    position: relative;
    min-height: 150px;
    height: 100%;
    width: 500px;

    .spinner {
      position: absolute;
      z-index: 5;
      left: calc(50% - 20px);
      top: calc(50% - 30px);
    }

    .html-editor {
      &:has(.error-msg) {
        .tox-tinymce {
          border: 1px solid #eb3d26;
        }
      }

      .error-msg {
        display: block;
        color: #eb3d26;
        font-size: 12px;
        line-height: 13px;
      }
    }
  }
}

@media (max-width: 767px) {
  .html-field .html-field__wrapper {
    width: 100%;
  }
}
