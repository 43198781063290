.relocation-steps {
  .compensation-form__container {
    .compensation-notify {
      padding-left: 15px;
    }
  }
  .compensation-form__container:after {
    content: '';
    display: block;
    width: 135px;
    height: 3px;
    background-color: #ff0000;
    margin-top: 30px;
    margin-bottom: 30px;
  }
  .compensation-form-container {
    .textarea-group {
      .control-label {
        padding-left: 0;
      }
    }
    .responsible-select-top {
      min-width: 16.44rem;
    }

    .currency-form,
    .form-group {
      label {
        flex: unset;
        max-width: unset;
        width: unset;
        margin-top: 0;
        min-width: 18rem;
        // margin-right: 5.9rem;
      }
    }
  }
}

@media (max-width: 768px) {
  .relocation-steps {
    .compensation-request {
      .form-group {
        .horizontal {
          padding-left: 15px;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .compensation-form__container {
    .form-group.currency-form .currency-container {
      justify-content: flex-start;
    }
  }
}
