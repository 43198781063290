@media (min-width: 1280px) {
  .ant-tooltip {
    min-width: max-content;
  }
}

@media (max-width: 1279px) {
  .ant-tooltip {
    .ant-tooltip-inner {
      hyphens: auto;
    }
  }
}

@media (max-width: 592px) {
  .ant-tooltip {
    .ant-tooltip-inner {
      hyphens: auto;
    }
  }
}

.ant-tooltip-inner {
  color: black !important;
  background: white !important;
  font-size: 13px;
  font-weight: 600;
}

.ant-tooltip-arrow::before {
  background: white !important;
}

.links-icons__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.links-icons__top-left-tooltip {
  .ant-tooltip-arrow {
    left: -4px;
  }
}

.links-icon {
  fill: #7A7A7A;
  transition: 0.3s ease-in-out;
}

.links-icon.wod-icon:hover {
  fill: #0D600D;
}

.links-icon.smg-icon:hover {
  fill: #51ACAE;
}

.user-info {
  svg {
    fill: #7A7A7A;
    transition: 0.3s ease-in-out;
  }

  &:hover {
    svg {
      fill: #EB3D26;
      cursor: help;
    }
  }
}
