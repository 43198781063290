.responsible-top {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 10px;

  .responsible-select-top {
    margin-left: 10px;
    width: 65%;
    min-width: 12rem;
  }
}

@media (max-width: 992px) {
  .responsible-top {
    justify-content: left;
  }
}
