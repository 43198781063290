.attachment {
  display: inline-block;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  min-width: 120px;

  a {
    font-size: 1.2rem;
    line-height: 1.4;
    text-decoration: none;
  }

  a:hover {
    color: #333;
    text-decoration: none;
  }

  span {
    display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 120px;
    cursor: pointer;
  }

  .attachment-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
  }

  .close-attachment {
    width: 1rem;
    height: 1rem;
    margin-left: 0.5rem;
    cursor: pointer;
  }

  a {
    display: flex;
  }

  .delete-attachment {
    display: flex;
  }

  .icon {
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    width: 2rem;
    max-height: 2.5rem;
    vertical-align: top;
    cursor: pointer;
  }
}

.DeleteAttachmentModal {
  .title {
    font-weight: bold;
    font-size: 22px;
    margin-bottom: 1rem;
    // line-height: 25px;
  }
  .content-modal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1rem;
  }

  .wrapper .modal-container {
    padding: 1rem 1rem 2rem;
    margin-top: 0;
  }

  .button {
    width: 7.5rem;
  }
}

@media (min-width: 1500px) and (max-width: 1700px) {
  .DeleteAttachmentModal {
    .wrapper {
      .modal-container {
        flex: 0 0 30%;
        max-width: 30%;
      }
    }
  }
}

@media (min-width: 1200px) and (max-width: 1499px) {
  .DeleteAttachmentModal {
    .wrapper {
      .modal-container {
        flex: 0 0 35%;
        max-width: 35%;
      }
    }
  }
}

@media (max-width: 857px) {
  .DeleteAttachmentModal {
    .wrapper .modal-container {
      padding: 1rem;
      top: unset;
    }
  }
  .attachment {
    .icon {
      max-height: 2rem;
    }
  }
}
