.notes {
  display: flex;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }
}

.vacAppointment-container {
  .vacApointment-form {
    .vacAppointment-title__wrapper {
      display: flex;
      justify-content: space-between;
      max-width: 500px;

      .vacAppointment-title__container {
        display: flex;
      }

      .vacAppointment-id {
        display: flex;
        font-weight: 500;
        color: #c4c4c4;
        margin-left: 4px;
      }

      .cancel-button {
        display: flex;
        position: unset;
        margin-left: unset;
        margin-bottom: unset;
        margin-top: 4px;
      }
    }
  }

  .note-container {
    .note-cancel__container {
      .cancel-button {
        margin-top: 10px;
        margin-left: 1rem;
        position: unset;
      }
    }
  }

  .delete-form-container {
    display: flex;
    margin-left: unset;
    margin-bottom: unset;
    margin-top: 4px;
  }

  .form-group {
    margin-bottom: 25px;
  }
}

.visa-appeal {
  .form-group {
    flex-wrap: wrap;
    display: flex;
    align-items: center;

    &:has(.error) {
      margin-bottom: 9px;

      .form-control {
        border-color: #eb3d26;
      }

      .responsible-select-top {
        div {
          border-color: #eb3d26;
        }
      }
    }

    .error {
      width: 100%;
      padding-left: 200px;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

@media (min-width: 768px) {
  .vacAppointment-container {
    .radio-line {
      .control-label {
        max-width: 480px;
      }
    }
  }
}

@media (max-width: 767px) {
  .vacAppointment-container {
    .vacApointment-form {
      .cancel-button {
        margin-left: 0;
      }
    }

    .delete-form-container {
      margin-left: 0;
    }
  }
  .vacAppointment-container .vacApointment-form .vacAppointment-title__wrapper {
    max-width: 100%;
  }
}
