@import '../../../assets/css/variables.scss';

.ask-question {
  // height: calc(100vh - 254px - 75px);
  img {
    float: right;
    padding-right: 30px;
    padding-left: 100px;
  }
  .ask-question__title {
    font-weight: 300;
    font-size: 32px;
    margin: 0;
    &::after {
      content: '';
      display: block;
      width: 124px;
      height: 2px;
      background-color: $red;
    }
  }

  .ask-question__form-container {
    padding-top: 30px;
    padding-bottom: 100px;

    .ask-question__form {
      display: flex;
      flex-direction: column;

      .main-btn.btn-form {
        margin: 24px 0 0;
      }
      .main-btn {
        padding: 10px 24px;
      }
      .ask-question__btn-wrapper {
        display: flex;
        justify-content: flex-end;
      }
      .ask-question__form-textarea {
        min-height: 174px;
        resize: none;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        padding: 8px 16px;
        &:focus-visible {
          outline: none;
          border: 1px solid $red;
        }
      }
      .error {
        font-size: 14px;
      }
    }
  }
}

@media (max-width: 1439px) {
  .ask-question {
    img {
      float: right;
      padding-right: 0;
      padding-left: 30px;
      width: 40%;
    }
  }
}

@media (max-width: 992px) {
  .ask-question {
    img {
      display: none;
    }
  }
}
