.employee-relocated {
  .custom-checkbox .custom-checkbox__label {
    cursor: auto;
  }

  .control-label {
    margin-right: 1rem;
  }
}

@media (max-width: 320px) {
  .employee-relocated {
    .control-label {
      margin-right: 0.7rem;
    }

    .form-group .option-inline {
      margin-right: 0;
    }
  }
}
