.employee-select-documents__button-container {
  position: absolute;
  bottom: 2rem;
  right: 1.5rem;
  display: flex;
  justify-content: flex-end;

  .main-btn.btn-form {
    margin: 0;
    margin-left: 1rem;
  }
}

.employee-select-documents__forms-container {
  margin-bottom: 30px;
  .select-documents__title {
    font-weight: 500;
    font-size: 16px;
  }
}
