.statistic__visa-only {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px 80px;
}

@media (max-width: 1200px) {
  .statistic__visa-only {
    grid-template-columns: 1fr;
    gap: 120px;
  }
}

@media (max-width: 544px) {
  .statistic__visa-only {
    gap: 20px;
  }
}
