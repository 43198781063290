.delete-form-container {
  margin-left: 1.2rem;
}

.delete-icon {
  cursor: pointer;
  height: 1.5rem;
}

@media (max-width: 375px) {
  .delete-form-container {
    margin-left: 1rem;

    .delete-icon {
      height: 18px;
    }
  }
  .delete-icon {
    height: 18px;
  }
}
