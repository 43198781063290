.textarea-group {
  .error-textarea {
    font-size: 14px;
  }
  .tooltip-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;

    img {
      margin-bottom: 4px;
    }
  }
  .form-group .form-error-container {
    padding-left: 0;
    textarea {
      margin-left: 15px;
    }
  }
  .areaDisabled {
    min-height: 36px !important;
  }
}

@media (min-width: 768px) {
  .textarea-group {
    .text-area-form-width {
      max-width: calc(200px + 300px);
    }
  }
}

@media (min-width: 1200px) {
  .textarea-group {
    .text-area-form-width {
      max-width: calc(200px + 300px);
    }
  }
}

@media (max-width: 767px) {
  .textarea-group textarea {
    margin-top: 0;
  }
}
