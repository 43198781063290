@import '../../../../../assets/css/variables.scss';

.columns-filter__button {
  cursor: pointer;
  min-width: 160px;
  font-size: 14px;
  color: #66666680;
  background-color: #ffffff;
  border: 1px solid #d9d9d9;
  box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
  padding: 4px 15px;
  height: 32px;
  white-space: nowrap;
  text-align: center;
  transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  border-start-start-radius: 6px;
  border-end-start-radius: 6px;
  border-inline-end-width: 1px;
  border-start-end-radius: 6px;
  border-end-end-radius: 6px;
  &:hover {
    background-color: #c9c9c91a;
    color: rgba(0, 0, 0, 0.88);
  }
  svg {
    margin-bottom: 2px;
  }
  .columns-filter__button-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .columns-filter__button-name {
    font-size: 14px;
    svg {
      margin-right: 10px;
    }
  }
}

.columns-filter__button.ant-popover-open {
  background-color: #c9c9c91a;
  color: rgba(0, 0, 0, 0.88);
}

.columns-filter__checkbox-menu.ant-checkbox-group {
  display: flex;
  flex-direction: column;
}
.ant-popover-placement-bottomLeft {
  padding: 0;
}
.ant-popover {
  .ant-checkbox-wrapper {
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
    padding: 5px 12px 5px 10px;
    border-radius: 4px;
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
    }
  }
  .ant-checkbox + span {
    font-size: 14px;
  }
  //   .ant-checkbox-wrapper-checked {
  //     background-color: #fff4f0;
  //     &:hover {
  //       background-color: #ffd9cc;
  //     }
  //   }
  .ant-popover-inner {
    padding: 0;
    min-width: 280px;
  }
  .ant-popover-arrow {
    display: none;
  }
}

.columns-filter__checkbox-group {
  .columns-filter__checkbox-group-buttons {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(5, 5, 5, 0.06);
    padding: 7px 10px;
    .reset-btn {
      cursor: pointer;
      font-size: 14px;
      color: $red;
    }
    .ant-btn {
      font-size: 14px;
      height: 24px;
      padding: 0px 7px;
      border-radius: 4px;
    }
  }
}
