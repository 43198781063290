.time-input__wrapper {
  display: inline-block;
  padding: 0;
}

.time-field {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  label {
    margin-bottom: 0;
  }
}

.time-range-field {
  display: flex;
  justify-content: space-between;
  height: calc(1.5em + 0.75rem + 2px);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  .time-range__start-time.ant-picker {
    width: 40%;
  }
  .time-range__end-time.ant-picker {
    width: 50%;
  }

  .anticon-swap-right {
    color: rgba(0, 0, 0, 0.25);
  }

  .ant-picker-input {
    input {
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
    }
  }
}

.form-group.time-range .error {
  padding-left: 200px;
  width: 100%;
}

@media (max-width: 767px) {
  .time-input__wrapper {
    width: 100%;
  }
  .time-field {
    align-items: flex-start;
  }
  .form-group.time-range .error {
    padding-left: 0;
  }
}
