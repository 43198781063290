@import '../../../../assets/css/variables.scss';

.tableView-wrap {
  .table-wrapper {
    .table__head {
      .table__head-item {
        padding: 15px 20px;
      }
    }

    .table__body {
      .table__body-item {
        padding: 15px 20px;

        .logo-wrap {
          display: flex;
          align-items: center;
          .event-icon__wrapper {
            padding-left: 4px;
            width: 21px;
          }
        }
      }
    }
    .table_ .Notes {
      max-height: 10rem;
      overflow-y: auto;
      word-break: break-word;
    }
  }
  .settingsIcon-mobile {
    display: none;
    // visibility: hidden;
  }
  .shareButton.main {
    .share,
    .close {
      position: absolute;
      top: 9px;
      left: 9px;
      transition: all 150ms;
    }

    .share {
      transform: rotate(0) scale(1);
      opacity: 1;
      width: 20px;
      height: 20px;
    }

    .close {
      opacity: 0;
      transform: rotate(90deg) scale(0);
    }
  }
  .columns-section__wrap {
    position: sticky;
    top: 180px;
    .columns-section {
      padding-left: 16px;
      width: 200px;
      position: absolute;
      background-color: #fff;
      border: 1px solid #c4c4c4;
      top: -51px;
      z-index: 4;

      .form-group {
        margin-bottom: 6px;
      }

      .checkbox-reverse {
        display: flex;
      }
      .checkbox-reverse:first-child {
        padding-top: 10px;
      }
      .custom-checkbox {
        padding-right: 10px;
      }

      .column {
        padding: 5px;
      }

      .control-label {
        cursor: pointer;
      }
    }
  }

  .shareButton,
  .shareButton.open {
    position: absolute;
    top: 13.5rem;
    left: 3.5rem;
    z-index: 2;
    border: none;
    border-radius: 50%;
    background: #fff;
    padding: 9px;
    overflow: hidden;
    outline: none;
    margin: 0.2rem;
    width: 20px;
    height: 20px;
    box-sizing: content-box;
    transition: all 200ms;
    // position: relative;
    opacity: 1;
    transform: scale(1);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0);
    cursor: auto;
  }

  .shareButton:hover,
  .shareButton.open:hover {
    // transform: scale(1.1) translateY(-3px);
    cursor: pointer;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
  }

  // .shareButton:active,
  // .shareButton.open:active {
  //     cursor: auto;
  // }

  .shareButton svg,
  .shareButton.open svg {
    display: block;
    fill: #212529;
    width: 20px;
    height: 20px;
    opacity: 1;
    transition: all 150ms;
    transform: scale(1);
  }

  .shareButton.open img {
    animation: 0.3s linear 0.1s normal none running rot;
    -webkit-animation: 0.3s linear 0.1s normal none running rot;
  }
  .visa_bulk-update__container {
    display: flex;
    justify-content: flex-end;
    position: relative;

    .main-btn.btn-form.visa-application-bulk-update {
      margin: 0;
      position: absolute;
      top: -46px;
      right: calc(218px + 22px);
      width: 273px;
      &:disabled {
        opacity: 0.3;
      }
    }

    .main-btn:hover::before {
      opacity: 0;
    }
  }

  .generate-excel__container {
    display: flex;
    justify-content: flex-end;
    position: relative;

    .main-btn.btn-form.generate-excel {
      margin: 0;
      position: absolute;
      top: -46px;
      right: 0;
      width: 218px;
      &:disabled {
        opacity: 0.3;
      }
    }

    .main-btn:hover::before {
      opacity: 0;
    }
  }
  .disabled-fullname {
    font-weight: bold;
    cursor: help;
  }
}

.cases-container {
  .table-wrapper {
    overflow: unset;
  }
}

.case-item__reason-tooltip {
  font-size: 13px;
  font-weight: 600;
}

@media (max-width: 1350px) {
  .tableView-wrap {
    .settingsIcon-mobile {
      display: unset;
      visibility: unset;
    }
    .shareButton.main {
      top: 9.8rem;
      right: -0.1rem;
      left: unset;
      position: fixed;
    }
    .columns-section__wrap {
      z-index: 1;
      .columns-section {
        top: 13rem;
        right: 0;
        position: fixed;
      }
    }
    .shareButton.main .share,
    .shareButton.main .close {
      top: 9px;
      left: 9px;
    }
    .shareButton,
    .shareButton.open {
      padding: 9px;
    }

    .generate-excel__container {
      .main-btn.btn-form.generate-excel {
        position: unset;
        right: unset;
      }
    }

    .visa_bulk-update__container {
      .main-btn.btn-form.visa-application-bulk-update {
        position: unset;
        right: unset;
      }
    }
  }
}

@media (max-width: 1440px) {
  .tableView-wrap {
    .table-wrapper .table_ .table__body-item {
      padding: 10px 10px;
    }
    .table-wrapper .table_ .table__head-item {
      font-size: 15px;
      padding: 10px 10px;
    }
  }
}

@keyframes rot {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}

@-webkit-keyframes rot {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(45deg);
  }
}
