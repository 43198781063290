@import '../../../../assets/css/variables.scss';

.toggle-filter__container {
  .ant-space-item {
    font-size: 14px;
  }
  .toggle-filter__container-button {
    cursor: pointer;
    font-size: 14px;
    color: #66666680;
    background-color: #ffffff;
    border: 1px solid #d9d9d9;
    box-shadow: 0 2px 0 rgb(0 0 0 / 2%);
    padding: 4px 15px;
    height: 32px;
    white-space: nowrap;
    text-align: center;
    transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    border-start-start-radius: 6px;
    border-end-start-radius: 6px;
    border-inline-end-width: 1px;
    border-start-end-radius: 6px;
    border-end-end-radius: 6px;
    &:hover {
      background-color: #c9c9c91a;
      color: rgba(0, 0, 0, 0.88);
      svg {
        fill: #2c2c30;
      }
    }
    svg {
      margin-bottom: 2px;
      fill: #666666;
    }
  }
  .toggle-filter__container-button.ant-dropdown-open {
    background-color: #c9c9c91a;
    color: rgba(0, 0, 0, 0.88);
    svg {
      fill: #2c2c30;
    }
  }
  .filterActive {
    color: $red;
    svg {
      fill: $red;
    }
  }
}
