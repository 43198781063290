.logo-wrap {
  display: flex;
  align-items: center;
  margin-top: 3px;
}

.logo-wrap a {
  border-bottom: none;
}

.logo-wrap svg {
  width: 20px;
  margin-right: 4px;
}
