.general-document {
  display: flex;
  flex-direction: column;

  .doc-without-exp-date {
    display: flex;

    .document-value {
      display: flex;
      margin-left: 2px;
    }
  }

  .exp-date {
    white-space: nowrap;
  }

  .expirationInMonth {
    color: #eb3d26;
  }

  .entryDateDocumentWithExpirationInThreeMonths {
    color: #eb3d26;
  }
}
