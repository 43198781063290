.time-input__wrapper {
  display: inline-block;
  padding: 0;
}

.time-field {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  label {
    margin-bottom: 0;
  }
}

@media (max-width: 767px) {
  .time-input__wrapper {
    width: 100%;
  }
  .time-field {
    align-items: flex-start;
  }
}
