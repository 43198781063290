.status-container {
  &__select {
    min-width: 16rem;
    display: inline-block;
  }
  .status-container__disabled {
    .css-wxlcbk-placeholder {
      color: hsl(0, 0%, 60%);
    }
  }
}
