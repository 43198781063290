.final-checkbox-wrapper__block {
  display: flex;
  flex-wrap: wrap;
  padding-top: 1rem;
  padding-bottom: 1rem;

  .form-group {
    display: flex;
    flex-direction: row;
  }

  .control-label {
    flex: unset;
    max-width: unset;
  }
}

.relocation-request-details {
  .final-checkbox-wrapper__block {
    padding-top: 2rem;
  }
}

@media (max-width: 767px) {
  .final-checkbox-wrapper__block {
    form {
      width: 100%;
    }
    .form-group {
      justify-content: space-between;
    }
  }
}
