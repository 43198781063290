.send-docs__nota-bene {
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 40px;

  .bold {
    font-size: 16px;
    font-weight: 600;
  }
}
