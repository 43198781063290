.feedback-table {
  .ant-table-tbody .ant-table-row .ant-table-cell:nth-child(1) a {
    font-weight: 600;
    font-size: 14px;
  }

  .comments__container {
    .comment__title {
      font-size: 14px;
      line-height: 1.3;
      font-weight: 500;
      margin-right: 4px
    }

    .comment__top-line {
      border-top: 1px solid #ced4da;
      margin-top: 4px;
      padding-top: 4px;
    }

    .comment__text {
      font-size: 14px;
      line-height: 1.3;
    }
  }
}
