.guidline__container {
  .underlined {
    margin-bottom: 15px;
    &-text {
      margin-bottom: 45px;
    }
  }
  .guid-items {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 70px;
    padding-bottom: 50px;
    .guid-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;

      &__number {
        display: flex;
        position: relative;
      }
      &__count {
        position: absolute;
        top: 0;
        left: -30px;
        font-weight: 700;
      }
      &__text {
        text-align: center;
        padding-top: 8px;
        font-size: 14px;
      }
      .bold-font {
        font-size: 14px;
        font-weight: 600;
      }
    }
    .guid-item:not(:last-child):after {
      content: '';
      position: absolute;
      top: 32px;
      left: calc(50% + 62px);
      right: -38%;
      height: 0.5em;
      border-top: 1px solid #eb3d26;
      z-index: -1;
    }
  }
}

.guidline__container:after {
  content: '';
  position: absolute;
  //   bottom: 0;
  left: 0;
  right: 0;
  height: 0.5em;
  border-top: 1px solid #c9c9c9;
  z-index: -1;
}

@media (max-width: 767px) {
  .guidline__container {
    .guid-items {
      display: flex;
      flex-direction: column;
      grid-template-columns: unset;
      grid-column-gap: unset;
      .guid-item {
        flex-direction: row;
        margin-bottom: 10px;
      }
      .guid-item:not(:last-child):after {
        display: none;
      }
      .guid-item__count {
        margin-bottom: 0;
        position: unset;
        padding: 0 4px;
      }
      .guid-item__number {
        align-items: center;
      }
      .guid-item__text {
        padding-top: 0;
        text-align: start;
      }
      .guid-item__img {
        order: -1;
        height: 50px;
      }
    }
  }
}
