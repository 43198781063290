.request-employee-documents__container .documents__wrapper .documets__content {
  .comments {
    padding-bottom: 50px;

    .comments-text {
      margin-bottom: 2rem;
      max-width: 612px;
    }

    .html-field {
      display: flex;
      flex-wrap: wrap;

      .control-label {
        min-width: 102px;
        max-width: 102px;
        padding-top: 4px;
      }
    }

    .main-btn.btn-form {
      margin: 1rem 20px 10px 102px;
    }
  }
}

@media (max-width: 767px) {
  .request-employee-documents__container .documents__wrapper .documets__content {
    .comments {
      .main-btn.btn-form {
        margin: 1rem 20px 10px 0;
      }
    }
  }
}
