.after-relocation-steps-form {
  .icons {
    display: flex;
    align-items: center;
    position: absolute;
    top: -80px;

    .shareButton.main {
      .share,
      .close {
        position: absolute;
        top: 1rem;
        left: 1rem;
        transition: all 150ms;
      }

      .share {
        transform: rotate(0) scale(1);
        opacity: 1;
        width: 24px;
        height: 24px;
      }

      .close {
        opacity: 0;
        transform: rotate(90deg) scale(0);
      }
    }

    .shareButton.main.open {
      .close {
        transform: rotate(0) scale(1);
        opacity: 1;
        width: 24px;
        height: 24px;
      }

      .share {
        opacity: 0;
        transform: rotate(90deg) scale(0);
      }
    }

    .shareButton,
    .shareButton.open {
      border: none;
      border-radius: 50%;
      background: #fff;
      padding: 1rem;
      overflow: hidden;
      outline: none;
      margin: 0.5rem;
      margin-bottom: 16px;
      width: 24px;
      height: 24px;
      box-sizing: content-box;
      transition: all 200ms;
      position: relative;
      opacity: 1;
      transform: scale(1);
      box-shadow: 0 0 0 rgba(0, 0, 0, 0);
      cursor: auto;
    }

    .shareButton:hover,
    .shareButton.open:hover {
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
    }

    .shareButton:active,
    .shareButton.open:active {
      cursor: auto;
    }

    .shareButton svg,
    .shareButton.open svg {
      display: block;
      fill: #212529;
      width: 24px;
      height: 24px;
      opacity: 1;
      transition: all 150ms;
      transform: scale(1);
    }

    .ico {
      width: 0;
      height: 0;
      overflow: hidden;
      padding: 0;
      margin: 0;
      opacity: 0;
      transform: scale(0);

      .archive-icon,
      .delete-icon,
      .add-attachment,
      .edit-icon {
        cursor: pointer;
        height: 1.5rem;
      }

      svg {
        width: 0;
        height: 0;
        opacity: 0;
        transform: scale(0);
      }
    }
  }

  .children {
    display: flex;
    align-items: center;
    position: relative;
    margin-top: -1.6rem;
    margin-left: -0.4rem;
    left: 0 !important;
    bottom: 0;
  }
}

.document {
  .document-container {
    .document-form {
      .icons {
        display: flex;
        align-items: center;
        position: absolute;
        top: -80px;

        .delete-form-spinner {
          height: 24px;
        }

        .shareButton.main {
          .share,
          .close {
            position: absolute;
            top: 1rem;
            left: 1rem;
            transition: all 150ms;
          }
          .share {
            transform: rotate(0) scale(1);
            opacity: 1;
            width: 24px;
            height: 24px;
          }
          .close {
            opacity: 0;
            transform: rotate(90deg) scale(0);
          }
        }
        .shareButton.main.open {
          .close {
            transform: rotate(0) scale(1);
            opacity: 1;
            width: 24px;
            height: 24px;
          }
          .share {
            opacity: 0;
            transform: rotate(90deg) scale(0);
          }
        }

        .shareButton,
        .shareButton.open {
          border: none;
          border-radius: 50%;
          background: #fff;
          padding: 1rem;
          overflow: hidden;
          outline: none;
          margin: 0.5rem;
          width: 24px;
          height: 24px;
          box-sizing: content-box;
          transition: all 200ms;
          position: relative;
          opacity: 1;
          transform: scale(1);
          box-shadow: 0 0 0 rgba(0, 0, 0, 0);
          cursor: auto;
        }

        .shareButton:hover,
        .shareButton.open:hover {
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
        }

        .shareButton:active,
        .shareButton.open:active {
          cursor: auto;
        }

        .shareButton svg,
        .shareButton.open svg {
          display: block;
          fill: #212529;
          width: 24px;
          height: 24px;
          opacity: 1;
          transition: all 150ms;
          transform: scale(1);
        }

        .ico {
          width: 0;
          height: 0;
          overflow: hidden;
          padding: 0;
          margin: 0;
          opacity: 0;
          transform: scale(0);

          .archive-icon,
          .delete-icon,
          .add-attachment,
          .edit-icon {
            cursor: pointer;
            height: 1.5rem;
          }

          svg {
            width: 0;
            height: 0;
            opacity: 0;
            transform: scale(0);
          }
        }
      }
      .icons.children {
        display: flex;
        align-items: center;
        position: relative;
        margin-top: -1.6rem;
        margin-left: -0.4rem;
        left: 0 !important;
        top: 0;
      }
    }
  }
}

@media (max-width: 767px) {
  .document {
    .document-container {
      .document-form {
        .icons {
          display: flex;
          align-items: center;
          // position: absolute;
          // margin-top: -1.6rem;
          // margin-left: -0.4rem;
          // // left: 0 !important;
          // top: -54px;

          .shareButton:hover,
          .shareButton.open:hover {
            box-shadow: none;
            background: none;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .after-relocation-steps-form {
    .icons {
      display: flex;
      align-items: center;
      // position: relative;
      // margin-top: -1.4rem;
      // margin-left: -0.5rem;
      // left: 0 !important;
      // bottom: 0;

      .shareButton:hover,
      .shareButton.open:hover {
        box-shadow: none;
        background: none;
      }
    }
  }
}
