@import '../../../../../assets//css/variables.scss';

.HeaderSearch {
  margin-left: auto;
  ul,
  li {
    margin: 0;
    padding: 0;
    list-style-type: none;
  }
  position: relative;
  .search-wrapper {
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    justify-content: flex-end;
    position: relative;
    input {
      border: 1px solid #c4c4c4;
      padding: 6px 12px;
      border-radius: 4px;
      width: 100%;
      outline: none;
      width: 300px;
      transition: all 0.3s ease;
      background: #fff;
      // border: 1px solid black;
    }
    .search-icon {
      position: absolute;
      right: 0;
      height: 100%;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #333;
      border-radius: 4px;
      transition: all 0.3s ease;
    }
    .search-input:hover {
      border: 1px solid #c4c4c4;
    }
    &.hide {
      input {
        border: 0 none;
        width: 30px;
        background: transparent;
        font-size: 0;
      }
      .search-icon {
        color: $black;
      }
      &:hover {
        input {
          width: 300px;
          background-color: #fff;
          font-size: 16px;
        }
        .search-icon {
          color: #333;
        }
      }
    }
  }
  .search-results__wrapper {
    display: flex;
    flex-direction: column;
    position: fixed;
    background-color: #fff;
    border-radius: 4px;
    color: #333;
    width: 300px;
    top: 70px;
    border: 1px solid #c4c4c4;
    max-height: 300px;
    // overflow-y: scroll;
    .no-content {
      display: flex;
      align-items: center;
      margin: 10px;
    }
  }
  .search-results__wrapper.hide {
    display: none;
  }
  .search-results__items {
    display: block;
    overflow-y: hidden;
    .employee-name {
      font-size: 18px;
      line-height: 24px;
    }
    &.no-content {
      padding: 8px;
      display: flex;
      font-size: 18px;
      line-height: 24px;
    }
    a {
      padding: 8px;
      display: flex;
      //   box-shadow: 0px -4px 5px -5px rgba(34, 60, 80, 0.6) inset;
      transition: all 0.3s ease;
    }
    &:last-child a {
      box-shadow: none;
    }
    a:hover {
      text-decoration: none;
      color: #333;
      background-color: #ececee;
    }
  }
  .search-results__items:hover {
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    -moz-overflow-scrolling: touch;
    -ms-overflow-scrolling: touch;
    -o-overflow-scrolling: touch;
  }
  .search-results__items::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 7px;
  }
  .search-results__items::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background-color: #c4c4c4;
    box-shadow: 0 0 1px #c4c4c4;
  }
}

@media (max-width: 768px) {
  .mobile-search {
    position: absolute;
    top: 50%;
    left: 80%;
    transform: translate(-81%, -50%);
    z-index: 3;
    .HeaderSearch {
      .search-wrapper {
        &.hide {
          &:hover {
            input {
              width: 95vw;
              height: 50px;
            }
          }
        }
        &:hover {
          input {
            width: 95vw;
            height: 50px;
          }
        }
        input {
          // width: 95vw;
          height: 50px;
        }
      }
      .search-results__wrapper {
        width: 95vw;
      }
      .search-results__item {
        min-height: 35px;
      }
    }
  }
}

@media (max-width: 680px) {
  .mobile-search {
    left: 75%;
    transform: translate(-76%, -50%);
  }
}

@media (max-width: 540px) {
  .mobile-search {
    left: 70%;
    transform: translate(-71%, -50%);
  }
}

@media (max-width: 430px) {
  .mobile-search {
    left: 65%;
    transform: translate(-66%, -50%);
  }
}

@media (max-width: 370px) {
  .mobile-search {
    left: 60%;
    transform: translate(-60%, -50%);
  }
}
