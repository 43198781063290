.relocated-employees {
  .relocated-employees__header-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 50px;
    .main-btn.btn-form {
      margin: 0 0 0 10px;
    }
    h3 {
      margin-bottom: 0;
    }
  }
  .relocated-employees__additional-filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;
    flex-wrap: wrap;
    .relocated-employees__additional-filters-left-group {
      position: sticky;
      left: 3.75rem;
      flex-wrap: wrap;
      display: flex;
      gap: 12px;
    }
  }
  .panel-top-row {
    position: sticky;
    left: 0;
  }
  .ant-table-wrapper .ant-table-tbody {
    .ant-table-cell .table__body-item-content {
      div,
      p {
        font-size: 14px;
      }
    }
  }
  .logo-wrap {
    gap: 4px;
  }
  .ant-table-tbody .ant-table-row .ant-table-cell:nth-child(1) div,
  .ant-table-cell:nth-child(2) a {
    font-size: 14px;
    font-weight: 600;
  }
  .ant-table-tbody .ant-table-row .ant-table-cell .logo-wrap {
    .wodLogo,
    .user-info {
      svg {
        margin-right: 4px;
      }
    }

    .wodLogo {
      svg {
        height: 21px;
        width: 22px;
      }
    }

    .user-info {
      svg {
        height: 19px;
        width: 22px;
      }
    }

    .smgLogo {
      svg {
        width: 20px;
        height: 22px;
      }
    }
    a:hover,
      a {
        border-bottom: none;
      }
  }

  .ant-table-tbody .ant-table-row .ant-table-cell:nth-child(7) div {
    word-wrap: break-word;
  }
}

@media (max-width: 992px) {
  .relocated-employees .panel-top-row {
    left: 3vw;
  }
  .relocated-employees {
    .relocated-employees__additional-filters {
      .relocated-employees__additional-filters-left-group {
        left: 3vw;
      }
    }
  }

  .relocated-employees .table-wrapper .table_ .table__body-item {
    padding: 10px 20px;
  }
  .relocated-employees {
    .logo-wrap {
      display: flex;
      justify-content: flex-end;
    }
  }
}

@media (max-width: 767px) {
  .relocated-employees {
    .relocated-employees__additional-filters {
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
    }
  }
  .relocated-employees {
    .search-form__container,
    .search-form__container .search-form__input {
      width: 100%;
    }
    .candidate-filters {
      .filter-tab {
        width: 33.3%;
        padding: 0 2px 10px;
      }
    }
  }
}
