.after-relocation-steps-form {
  margin-bottom: 30px;

  .extraSteps-form {
    position: relative;
  }

  .label-message {
    flex: 0 0 40%;
    max-width: 40%;
    .error-textarea {
      padding-left: 1rem;
    }
  }

  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  .after-relocation-steps-form {
    .form-group {
      .form-control {
        min-width: fit-content;
      }
    }
  }
}

@media (max-width: 767px) {
  .after-relocation-steps-form {
    .form-group {
      .responsible-select-top {
        margin-left: 0;
      }
      .custom-checkbox {
        &.custom-control {
          padding-left: 0;
        }
      }
      .control-label,
      .label-message,
      .form-error-container {
        padding-left: 0;
      }
      .form-control {
        margin-left: 0;
      }
    }
    .buttons-container {
      .button {
        margin-left: 0;
      }
    }
    .custom-radio {
      .control-label,
      .relocation-group {
        padding-left: 0;
      }
    }
  }
}

@media (max-width: 576px) {
  .after-relocation-steps-form {
    .edit-btn {
      display: none;
    }
  }
}
