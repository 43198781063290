.table-wrapper {
  margin-bottom: 20px;
  border-radius: 2px;
  overflow: auto;

  .table_ {
    width: 100%;
    box-shadow: 0 2px 0 0 rgb(0 0 0 / 14%), 0 0 0 -2px rgb(0 0 0 / 20%), 0 0 0 0 rgb(0 0 0 / 12%);

    .table__head {
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      border: 1.5px solid #f3f3f3;
      border-bottom: 2px solid red;

      &-item {
        font-weight: 500;
        text-align: left;
        padding: 20px 30px;
        font-size: 16px;
        color: #7a7a7a;
      }

      &-item:first-child {
        border-radius: 8px 0 0 0;
      }

      &-item:last-child {
        border-radius: 0 8px 0 0;
      }
      &-list th {
        vertical-align: top;
      }
    }

    .table__body {
      &-item {
        text-align: left;
        padding: 20px 30px;
        border: none;
        font-size: 16px;

        .dropdown-cell {
          width: max-content;
          .responsible-select {
            min-width: 9rem;
          }
        }
      }

      .textAlign {
        text-align: center;
      }

      &-item:first-child {
        border-radius: 8px 0 0 8px;
        border-left: none;
        font-weight: bold;
      }

      &-item:last-child {
        border-radius: 0 8px 8px 0;
        border-right: none;
      }

      &-text {
        font-weight: inherit;
        word-wrap: break-word;

        .status-container {
          .status-container__title {
            display: none;
          }
          .status-container__select {
            min-width: 10rem;
          }
          // .status-container__select > div {
          //   width: fit-content;
          // }
        }

        .logo-wrap {
          .wodLogo,
          .user-info {
            svg {
              margin-right: 4px;
            }
          }

          .wodLogo,
          .user-info {
            svg {
              height: 20px;
            }
          }

          .smgLogo {
            svg {
              width: 20px;
            }
          }

          a:hover,
          a {
            border-bottom: none;
          }
        }
      }

      &-text::first-letter {
        text-transform: uppercase;
      }

      .table__body-list:nth-child(2n) {
        background: #f3f3f3;
      }

      a {
        border-bottom: 1px solid transparent;
        transition: all 0.2s ease;
        font-weight: bold;
        &:hover {
          border-bottom: 1px solid #ff0000;
          text-decoration: none;
          color: #333;
        }
      }

      p {
        margin-bottom: 0;
        &::first-letter {
          text-transform: uppercase;
        }
      }
    }
  }
}

@media screen and (max-width: 992px) {
  .table-wrapper {
    .table_ {
      .table__head {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      .table__head-list {
        display: block;
        margin-bottom: 20px;
        box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
      }

      .table__body-item {
        border-bottom: 1px solid #ddd;
        display: block;
        font-size: 16px;
        text-align: right;
        padding: 10px 20px;
        display: flex;
        justify-content: space-between;
        .custom-checkbox span {
          margin-right: 0;
        }
        &::before {
          content: attr(data-label);
          font-weight: bold;
          display: flex;
          text-align: left;
        }
        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }
}
