.custom-radio {
  margin-bottom: 1rem;

  label.required:after {
    content: '*';
    color: red;
  }

  .control-label {
    max-width: 200px;
    width: 100%;
    padding-right: 15px;
  }

  .error {
    font-size: 14px;
  }

  .control-label {
    margin: 0;
    line-height: 24px;
    font-weight: 500;
  }

  .control-label {
    margin: 0 0 8px;
  }

  img {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.4rem;
  }

  .relocation-type {
    padding: 0.5rem 2.5rem;
  }

  .relocation-group {
    margin-bottom: 2px;
    display: flex;
    flex-direction: column;
    padding-right: 24px;
    .relocation-group-radio {
      display: flex;
      align-items: center;
      line-height: 22px;

      .custom-radio__label {
        display: inline-flex;
        margin: 0;
        cursor: pointer;
        transition: all 0.1s ease;
        &:hover {
          opacity: 0.8;
          span::after {
            background-color: #ff0000;
          }
        }
      }
      .custom-radio__label span {
        background-image: url('../../../../assets/img/buttons/Radio_empty.svg');
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        height: 18px;
        width: 18px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;
      }
      .custom-control-input:checked + .custom-radio__label span {
        background-position: center;
        background-size: 100%;
        background-repeat: no-repeat;
        background-image: url('../../../../assets/img/buttons/Radio_checked.svg');
      }
    }
  }

  .custom-control-input:disabled + .custom-radio__label span {
    opacity: 0.6;
    cursor: not-allowed;
  }

  .radios-error-container {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .error {
    margin-bottom: 1rem;
  }
  label {
    margin: 0;
  }
  .radio-option {
    cursor: pointer;
  }
}

.radio-horizontal {
  display: flex;
  flex-wrap: wrap;
}

.custom-radio {
  .tooltip-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;

    img {
      margin-bottom: 4px;
    }
  }
}
