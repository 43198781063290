.display-attachment {
  position: relative;
  cursor: pointer;
  // display: inline-block;
  text-align: center;
  margin-right: 8px;
  margin-bottom: 12px;

  .attachment-name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 10px;
    width: 60px;
    padding: 0 6px;
    color: #666666;
    cursor: pointer;
  }

  .attachment-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 8px;
    width: 60px;
    height: 60px;
    border: 1px solid #d9d9d9;
  }

  .close-attachment {
    width: 8px;
    height: 8px;
    cursor: pointer;
  }

  .delete-attachment {
    position: absolute;
    top: -4px;
    right: -4px;
    width: 18px;
    height: 18px;
    background-color: white;
    box-shadow: 0px 0px 13px rgb(0 0 0 / 5%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .icon {
    height: 24px;
    width: 18px;
    margin-bottom: 4px;
    cursor: pointer;
  }
}
