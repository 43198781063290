.app-wrapper-main {
  padding-top: 75px;
  margin: 0 auto;
  height: 100vh;
}

.app-wrapper-secondary {
  height: 100%;
  padding-bottom: 20rem;
}

.max-width {
  max-width: 1620px;
}
