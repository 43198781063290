@import '../../../../assets/css/variables.scss';

.send-email__status {
  h4 {
    font-weight: 600;
  }

  .underlined:after {
    width: 76px;
    height: 2px;
  }

  .wrapper {
    .modal-container {
      max-width: 490px;
      min-height: 254px;
    }

    .exit-button {
      img {
        top: 45px;
      }
    }
  }

  .modal-message {
    .main-btn.btn-form {
      margin: 0;
    }

    .ok-btn {
      margin: 0;
      position: absolute;
      bottom: 40px;
      right: 40px;
    }
    .message {
      line-height: 1.5;
      a {
        font-weight: 500;
        &:hover {
          color: $primary;
        }
      }
    }
  }
}
