@import '../../../../assets/css/variables';

.related-page {
  padding: 0;
  padding-bottom: 15.625rem;
  font-family: $primary-font;
  min-height: 100%;
  .content {
    p {
      font-weight: 300;
      font-size: 1.125rem;
      line-height: 1.875rem;
    }
    h2 {
      font-size: 67px;
      font-weight: 300;
      padding-left: 0;
      margin-bottom: 0;
    }
    .block {
      margin-bottom: 80px;
    }
  }
}

@media (max-width: 992px) {
  .related-page {
    h2 {
      font-size: 50px;
    }
  }
}

@media (max-width: 768px) {
  .related-page {
    h2 {
      font-size: 35px;
    }
  }
}
