@import '../../../assets/css/variables';

.relocation-package {
  .relocation-package__wages {
    &-title {
      margin: 0;
    }

    .main-btn.btn-form {
      margin: 0;
    }
  }
  .relocation-package__info {
    padding-top: 58px;
    .relocation-package__info-title {
      padding-top: 50px;
    }
  }

  .relocation-package__wages img,
  .relocation-package__info img {
    float: right;
    padding-right: 150px;
    padding-left: 80px;
  }

  .relocation-package__wages-content,
  .relocation-package__info-content {
    padding: 24px 0 8px;
    p {
      margin-bottom: 24px;
      font-size: 18px;
      line-height: 24px;
    }
    a {
      font-size: 18px;
      line-height: 24px;
      color: $red;
      border-bottom: 1px solid $red;
    }
  }

  .relocation-package__wages .relocation-package__wages-title p,
  .relocation-package__info .relocation-package__info-title {
    font-size: 40px;
    font-weight: 700;
    line-height: 52px;
    margin: 0;
  }
  .relocation-package__wages .relocation-package__wages-title,
  .relocation-package__info .relocation-package__info-title {
    &::after {
      content: '';
      display: block;
      width: 70px;
      height: 2px;
      background-color: $red;
      margin-top: 6px;
    }
  }
}

@media (min-width: 1500px) {
  .relocation-package {
    .relocation-package__wages img,
    .relocation-package__info img {
      padding-right: 150px;
      padding-left: 100px;
      padding-bottom: 0;
    }
    .relocation-package__wages-content p,
    .relocation-package .relocation-package__info-content p {
      line-height: 26px;
    }
  }
}

@media (min-width: 993px) and (max-width: 1439px) {
  .relocation-package {
    .relocation-package__wages img,
    .relocation-package__info img {
      padding-right: 0;
      padding-left: 0;
      padding-bottom: 0;
    }
  }
}

@media (max-width: 992px) {
  .relocation-package {
    .relocation-package__wages img,
    .relocation-package__info img {
      display: none;
    }
    .relocation-package__info {
      .relocation-package__info-title {
        padding-top: 0;
      }
    }
  }
}

@media (max-width: 544px) {
  .relocation-package {
    .relocation-package__wages .relocation-package__wages-title p,
    .relocation-package__info .relocation-package__info-title {
      font-size: 30px;
    }
    .relocation-package__wages-content p,
    .relocation-package__info-content p {
      margin-bottom: 16px;
      font-size: 16px;
      line-height: 24px;
    }
  }
}
