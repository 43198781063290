.legalization__generation-excel-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;

  &:disabled {
    background: #c4c4c4;
    border: 1px solid #c4c4c4;
  }
}
.legalization__generation-excel-btn.main-btn.btn-back {
  margin: 0;
}

.legalization__generation-excel-btn.button {
    min-height: 32px;
    height: 32px;
    font-size: 15px;
    display: flex;
    justify-content: center;
    min-width: 138px;
    width: 110px;
    border: 1px solid #EB3D26;
    border-start-start-radius: 6px;
    border-end-start-radius: 6px;
    border-inline-end-width: 1px;
    border-start-end-radius: 6px;
    border-end-end-radius: 6px;
    padding: 4px 12px;
    margin-bottom: 4px;
}

.legalization__generation-excel-btn.main-btn.btn-back:disabled,
.legalization__generation-excel-btn.main-btn.btn-back[disabled] {
  background: #c4c4c4;
  border: 1px solid #c4c4c4;
}
