@import '../../../assets/css/variables.scss';

@media (max-width: 992px) {
  .education_document {
    .education_document-container {
      .education_document-form {
        &-buttons {
          right: 30%;
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .education_document {
    .education_document__title,
    .title-container .icon {
      margin-bottom: 1rem;
      padding-left: 0;
    }
  }
}
