.employee-documents__container {
  .content__container {
    padding-top: 50px;
  }
  ol li {
    line-height: 1.5;
  }
  .employee-intro-text {
    margin-bottom: 3rem;
  }
}

@media (max-width: 767px) {
  .employee-documents__container .content__container {
    padding-top: 16px;
  }
}
