.relocation-history-details,
.relocation-relative-history-details,
.relocation-pet-history-details,
.profile-history {
  .ant-table-wrapper .ant-table-content .ant-table-thead {
    top: 159px;
  }
  .ant-table-tbody .ant-table-row .ant-table-cell:nth-child(1) div {
    font-weight: 600;
  }
}
