.edit-btn {
  float: right;
  height: 1.8rem;
  margin-left: 10px;
  cursor: pointer;
  background-image: url('../../assets/img/edit-button.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.GeneralInfo h2 {
  margin-bottom: 30px;
  font-weight: bold;
  font-size: 28px;
}

.employee-profile {
  &__wrapper {
    position: relative;
  }

  .GeneralInfo {
    .form-group {
      .control-label {
        padding-top: 10px;
      }
    }
  }

  .status-container__select {
    min-width: 12rem;
  }

  .location {
    font-size: 1.7rem;
  }

  .request-top {
    &__right {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    .EmployeeName__wrapper {
      display: flex;

      .smgLogo,
      .wodLogo {
        padding-left: 15px;

        svg {
          width: 33px;
        }
      }
    }
  }
  .employee-profile__container {
    display: flex;
    min-height: calc(100vh - 190px);
    position: relative;

    .employee-profile__content {
      width: calc(100% - 230px);
      padding-left: 32px;
      border-left: 1px solid #c9c9c9;
      padding-top: 24px;
    }
  }

  .employee-profile__content:has(.profile-history, .employee-profile__cases, .employee-comments) {
    .additional-info-top {
      display: none;
    }
  }
}

.employee-profile {
  .table-wrapper {
    overflow: unset;
  }
}

@media (max-width: 1350px) {
  .employee-profile {
    .tableView-wrap {
      .settingsIcon-mobile {
        display: none;
        visibility: unset;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .employee-profile {
    .request-top {
      &__right {
        align-items: flex-start;
      }
    }
    .employee-profile__container {
      flex-direction: column;
      .employee-profile__content {
        width: 100%;
        padding-left: 0;
        border-left: unset;
        padding-top: 16px;
      }
    }
  }
}
