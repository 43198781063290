@import '../../../../../assets/css/variables.scss';

.select-documents {
  .wrapper .modal-container .displayVacIsRequired .form-group .control-label {
    width: 38%;
    flex: 0 0 38%;
  }
  .wrapper .modal-container .displayVacIsRequiredForRelatives .form-group .control-label {
    width: 38%;
    flex: 0 0 38%;
  }
  .displayVacIsRequired,
  .displayVacIsRequiredForRelatives {
    .control-label {
      padding-left: 0;
    }
  }
  // .wrapper .modal-container .form-group input {
  //   width: 100%;
  //   flex: 0 0 100%;
  // }
  .employee__title {
    font-weight: 500;
    font-size: 20px;
    color: $red;
  }
}

.select-documents-send {
  .wrapper .modal-container {
    min-height: 500px;
    max-width: 600px;
  }
  .select-documents-send__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    h3 {
      margin: 0;
    }
    img {
      cursor: pointer;
    }
  }
  .date {
    .date-field {
      flex-wrap: wrap;
    }
  }
  .form__container {
    display: inline-block;
    columns: 2 auto;
    max-width: 100%;
    width: 100%;
  }
  .form-group {
    margin-bottom: 6px;
  }
}

@media (max-width: 767px) {
  .select-documents .wrapper .modal-container .date .control-label {
    width: 100%;
    flex: 0 0 100%;
  }
  .select-documents .wrapper .modal-container .date .ant-datepicker-wrapper {
    flex: 100%;
    max-width: 100%;
  }
  .select-documents .wrapper .modal-container .form-group .error {
    padding-left: 0;
  }
  .select-documents .wrapper .modal-container .displayVacIsRequired .form-group .control-label {
    width: 50%;
    flex: 0 0 50%;
  }
}
