.statistic__section {
  .tabs-download-excel__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 0 60px;
  }
  .panel-top-row {
    margin-bottom: 50px;
  }

  .tabs {
    display: flex;
    .tab-item {
      background: #fff;
      color: #000000;
      border: none;
      border-bottom: 1px solid #c9c9c9;
      font-size: 18px;
      font-weight: 500;
      padding: 0 20px 10px;
    }

    .active .tab-item {
      color: #eb3d26;
      border-bottom: 2px solid #eb3d26;
      font-weight: 600;
    }
  }
}

@media (max-width: 767px) {
  .statistic__section .tabs .tab-item {
    font-size: 16px;
  }

  .statistic__section {
    .tabs-download-excel__container {
      flex-direction: column;
      align-items: flex-start;
      margin: 0;
    }
  }
  .statistics__generation-excel-btn.main-btn.btn-back {
    margin: 30px 0 38px;
  }
}

@media (max-width: 544px) {
  .statistic__section .tabs .tab-item {
    font-size: 16px;
    padding: 0 10px 10px;
  }
}
