.relative-wrapper {
  position: relative;
}
.wrapper {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 1000;
  padding: 5rem;
  overflow: auto;

  .modal-container {
    position: absolute;
    margin-top: 1%;
    background: #fff;
    border-radius: 5px;
    padding: 32px 40px 40px;
    min-width: 300px;
    .relative-title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .form-group {
      display: flex;
      align-items: center;
    }
  }
  .exit-container {
    display: flex;
    justify-content: flex-end;
  }
  .exit-button {
    border: 0;
    background-color: #fff;
    img {
      $size: 10px;
      width: $size;
      height: $size;
      top: 32px;
      right: 40px;
      position: absolute;
    }
  }

  button:focus {
    outline: none;
  }
}

@media (max-width: 1600px) {
  .wrapper {
    padding: 1rem;
  }
}

@media (max-width: 857px) {
  .wrapper {
    .modal-container {
      padding: 16px 16px 16px;
      width: 93%;
      // top: 5%;
      .country-container {
        .form-group {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .form-error-container div {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            select {
              margin-top: 5px;
            }
          }
        }
      }
    }
    .exit-button {
      img {
        top: 16px;
        right: 16px;
      }
    }
  }
}
