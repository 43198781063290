@import '../../../assets/css/variables.scss';

.dark-modal-container {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: rgba($color: #000000, $alpha: 0.7);
  z-index: 6;
  padding: 5rem;
  overflow: auto;

  .modal-container {
    position: absolute;
    margin-top: 5%;
    background: $black;
    padding: 1rem 1rem 1rem;
    min-width: 320px;
    color: $white;
    .relative-title {
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .form-group {
      display: flex;
      align-items: center;
      label {
        width: 40%;
        margin-bottom: 0;
        max-width: 100%;
        flex: 0 0 40%;
      }
      select,
      input {
        width: 60%;
        max-width: 100%;
        flex: 0 0 60%;
      }
    }
  }

  .exit-container {
    display: flex;
    justify-content: flex-end;
  }
  .exit-button {
    border: 0;
    background-color: $black;
    padding: 0;
    img {
      $size: 2rem;
      width: $size;
      height: $size;
    }
    button {
      padding: 0;
    }
  }

  button:focus {
    outline: none;
  }
}

@media (min-width: 720px) {
  .dark-modal-container {
    .modal-container {
      max-width: 28rem;
    }
  }
}

@media (max-width: 1600px) {
  .dark-modal-container {
    .wrapper {
      padding: 1rem;
    }
  }
}

@media (max-width: 857px) {
  .dark-modal-container {
    .wrapper {
      .modal-container {
        padding: 16px 16px 16px;
        width: 93%;
        .country-container {
          .form-group {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .form-error-container div {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              select {
                margin-top: 5px;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 576px) {
  .dark-modal-container {
    .modal-container {
      max-width: 90%;
      min-width: 288px;
    }
  }
}
