.ContactInfo {
  .cancel-button {
    top: unset;
    left: 710px;
  }
}

@media (max-width: 767px) {
  .ContactInfo {
    .addressField {
      display: flex;
    }
    .cancel-button {
      left: unset;
    }
  }
}
