.attach-photos-dropzone {
  display: flex;
  flex-wrap: wrap;

  label {
    font-weight: 500;
  }

  .dropzone-text {
    max-width: 260px;
    font-weight: 300;
    margin-bottom: 0;
  }

  .available-files {
    font-size: 12px;
    margin-bottom: 14px;
    color: #8c8c8c;
  }

  .drag-available-files {
    color: #5b5b5b;
  }

  .dropzone-image {
    width: 44px;
    height: 30px;
    opacity: 0.15;
  }

  .drag-dropzone-image {
    width: 44px;
    height: 30px;
    opacity: 0.3;
  }

  .dropzone-container {
    padding: 0;
  }

  .click-zone {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    border: 1px solid rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin: auto;
    min-height: 122px;

    &:focus-visible {
      outline: none;
    }

    &:hover {
      border: 1px solid #eb3d26;

      .dropzone-image {
        opacity: 0.3;
      }

      .available-files {
        color: #5b5b5b;
      }
    }
  }

  .drag-click-zone {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
    border: 1px solid #eb3d26;
    border-radius: 8px;
    margin: auto;
    min-height: 122px;
  }

  .btn {
    border: none;
    text-align: center;
    background-color: rgb(218, 216, 216);
    height: 50px;
    border-radius: 12px;
    color: black;
    font-weight: bold;
    transition-duration: 0.6s;
  }

  .btn:hover {
    background-color: blue;
    color: aliceblue;
  }

  .click-zone {
    cursor: pointer;
  }
}

@media (max-width: 767px) {
  .attach-photos-dropzone {
    flex-wrap: wrap;

    .dropzone-container {
      width: 100%;
    }
  }
}
