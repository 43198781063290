.legalization-documents {
  .ant-table-wrapper .ant-table-tbody > tr > td,
  .ant-table-wrapper .ant-table-thead > tr > th {
    padding: 16px 8px;
  }

  .ant-table-content .ant-table-tbody .ant-table-row .ant-table-cell {
    .event-tooltip {
      font-size: 14px;
    }

    .dropdown-cell {
      width: 185px;
    }

    .dropdown-cell.citizenship {
      width: 150px;
    }
  }

  .event-icon__wrapper {
    padding-top: 0;
    padding-left: 0;
  }

  .action-required {
    display: flex;
    justify-content: flex-end;
    //align-items: center;
    //gap: 10px;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-bottom: 40px;

    .main-btn.btn-form {
      margin: 0;
      margin-bottom: 14px;
    }
  }

  .ant-table-cell span,
  .ant-table-cell a,
  p {
    font-size: 14px;
  }

  .ant-table-cell p {
    margin-bottom: 0;
  }

  .panel-top-row {
    position: sticky;
    left: 3.75rem;
    margin-bottom: 14px;
  }

  .ant-table-tbody .ant-table-row .ant-table-cell:nth-child(1) div,
  .ant-table-cell:nth-child(2) a {
    font-weight: 600;
  }

  .ant-table-tbody .ant-table-row .ant-table-cell {
    .logo-wrap {
      .wodLogo,
      .user-info {
        svg {
          margin-right: 4px;
        }
      }

      .wodLogo {
        svg {
          height: 21px;
          width: 22px;
        }
      }

      .user-info {
        svg {
          height: 19px;
          width: 22px;
        }
      }

      .smgLogo {
        svg {
          width: 20px;
          height: 22px;
        }
      }

      a:hover,
      a {
        border-bottom: none;
      }
    }
  }
}

.legalization-documents {
  // .fullName,
  .passport {
    white-space: nowrap;
  }
}

// toggle filter and search name

.additional-filters__container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;

  .additional-filters__container-first-group {
    position: sticky;
    left: 3.75rem;
    flex-wrap: wrap;
    display: flex;
    gap: 12px;
  }

  .candidate-filters {
    position: sticky;
    left: 3.75rem;

    .filter-tab {
      background: #fff;
      color: #000000;
      border: none;
      border-bottom: 1px solid #c9c9c9;
      font-size: 16px;
      font-weight: 500;
      padding: 0 20px 10px;
    }

    .filter-tab-active {
      color: #eb3d26;
      border-bottom: 2px solid #eb3d26;
      font-weight: 500;
    }
  }
}

@media (max-width: 1366px) {
  .additional-filters__container {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;

    .candidate-filters {
      margin-bottom: 1rem;
    }

    .search-form__container,
    .search-form__container .search-form__input,
    .candidate-filters {
      width: 100%;
    }

    .candidate-filters {
      .filter-tab {
        width: 33.3%;
        padding: 0 2px 10px;
      }
    }
  }
  .legalization-documents {
    .general-documents-messages__container .general-documents__container {
      grid-template-columns: unset;
    }
  }
}

@media (max-width: 992px) {
  .legalization-documents .panel-top-row {
    left: 3vw;
  }
  .additional-filters__container {
    .additional-filters__container-first-group {
      left: 3vw;
    }
  }

  .legalization-documents .table-wrapper .table_ .table__body-item {
    padding: 10px 20px;
  }
  .legalization-documents {
    .logo-wrap {
      display: block;
    }
  }
}
