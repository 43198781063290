.ant-tooltip-inner {
  ul {
    margin-block-start: unset;
    margin-block-end: unset;
    padding-inline-start: 25px;

    li {
      font-size: 13px;
      font-weight: 600;
    }
  }

}

.reminders {
  .spinner-container {
    min-height: 50vh;
  }

  .ant-table-content {
    .ant-table-cell:first-child {
      text-align: center;
    }

    .ant-table-column-title {
      font-weight: 500;
      font-size: 15px;
    }
  }

  .ant-table-tbody {
    .ant-table-row {
      .dropdown-cell {
        min-width: 130px;
      }

      .ant-table-cell {
        font-size: 14px;

        .employee-name {
          font-size: 14px;
          font-weight: 600;
        }

        a {
          font-size: 14px;
        }

        .reminder-text {
          white-space: pre-line;
          font-size: 14px;
        }
      }

      .ant-table-cell:first-child {
        text-align: center;
      }

      .ant-table-cell:nth-child(2),
      .ant-table-cell:nth-child(3) div {
        font-weight: 600;
      }
    }
  }

  .reminders__clear-filters-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 1.2rem;
  }
}

.reminders-dashboard__header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2rem;

  .underlined {
    margin-bottom: 0;

    &:after {
      height: 2px;
    }
  }
}

.additional-filters__container {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1.4rem;

  .reminders-toggle-filters {
    .filter-tab {
      background: #fff;
      color: #000000;
      border: none;
      border-bottom: 1px solid #c9c9c9;
      font-size: 16px;
      font-weight: 500;
      padding: 0 20px 10px;
    }

    .filter-tab-active {
      color: #eb3d26;
      border-bottom: 2px solid #eb3d26;
      font-weight: 500;
    }
  }
}

@media (max-width: 767px) {
  .additional-filters__container {
    flex-direction: column;
    align-items: flex-start;

    .reminders-toggle-filters {
      margin-bottom: 1rem;
    }

    .search-form__container,
    .search-form__container .search-form__input,
    .reminders-toggle-filters {
      width: 100%;
    }

    .reminders-toggle-filters {
      .filter-tab {
        width: 33.3%;
        padding: 0 2px 10px;
      }
    }
  }
}
