.archived-document {
  .document-container:not(:last-child):after {
    content: '';
    display: block;
    width: 135px;
    height: 3px;
    background-color: #ff0000;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .label-message {
    flex: 0 0 40%;
    max-width: 40%;
    .error-textarea {
      padding-left: 1rem;
    }
  }

  .custom-radio .radio-option,
  .custom-radio .relocation-group .relocation-group-radio .custom-radio__label,
  .custom-checkbox .custom-checkbox__label {
    cursor: default;
  }

  .custom-checkbox {
    .custom-checkbox__label {
      &:hover {
        opacity: 1;
        span::after {
          background-color: unset;
        }
      }
    }
  }

  .Document__title {
    padding-left: 15px;
    &::after {
      content: '';
      display: block;
      width: 80px;
      height: 2px;
      background-color: #eb3d26;
      margin-top: 5px;
    }
  }

  margin-bottom: 30px;
}

.attachments-block {
  display: flex;
  flex-wrap: wrap;
  max-width: 50%;
  margin-top: 15px;
}

@media (max-width: 767px) {
  .attachments-block {
    max-width: 100%;
  }
}
