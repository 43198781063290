.chart__container {
  .statistic__filters {
    padding-left: 20px;

    .statistic__radio-group-filters {
      margin-top: 34px;
      margin-bottom: 24px;
      .ant-radio span {
        font-size: 14px;
      }
    }
  }
  .statistic__title {
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    font-family: 'IBM Plex Sans';
  }
  .statistic__range-picker-total {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    .statistic__total {
      font-weight: 700;
      font-size: 20px;
      line-height: 26px;
      font-family: 'IBM Plex Sans';
    }
  }
  .apexcharts-zoom-icon.apexcharts-selected svg {
    fill: #eb3d26 !important;
  }

  .apexcharts-pan-icon.apexcharts-selected svg {
    stroke: #eb3d26 !important;
  }
  .apexcharts-reset-icon:hover svg,
  .apexcharts-menu-icon:hover svg {
    fill: #eb3d26 !important;
  }
  .apexcharts-xaxis-label tspan,
  .apexcharts-data-labels text {
    font-size: 14px;
  }
  .apexcharts-menu-icon {
    margin-left: 5px;
  }
}
