.documentTabs {
  display: flex;
  margin: 0 0 40px;
  flex-wrap: wrap;
  width: 100%;

  a:hover {
    text-decoration: none;
    color: black;
  }
  .tab-item__container {
    margin-right: 12px;
    margin-bottom: 12px;
  }
  .tab-item {
    position: relative;
    cursor: pointer;
    padding: 15px 20px;
    margin-right: 0;
    background: white;
    font-size: 21px;
    font-weight: 500;
    height: 100%;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    border: 0;

    &.active {
      color: #eb3d26;
    }

    &.add-new-relative-tab {
      color: #7a7a7a;
      border: 1px solid #7a7a7a;
      &.active {
        border: 1px solid #eb3d26;
        background-color: #eb3d26;
        color: white;
      }
    }

    &.add-new-existing-tab {
      color: #eb3d26;
      border: 1px solid #eb3d26;
      &.active {
        background-color: #eb3d26;
        color: white;
      }
    }

    .deleteRelative-img {
      position: absolute;
      top: -4px;
      right: -4px;
      background-color: white;
      box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.05);
      width: 18px;
      height: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;

      img {
        width: 8px;
        height: 8px;
      }
    }
  }
}

.documentTabs-wrapper .textarea-group {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}

@media (max-width: 992px) {
  .tab-item {
    font-size: 25px;
    line-height: 24px;
    padding: 10px;
    width: 100%;
    max-width: 300px;
    margin-bottom: 0.5rem;
  }

  .link {
    margin-right: 0.5rem;
  }
}

@media (max-width: 1600px) {
  .documentTabs {
    .tab-item {
      padding: 8px 16px;
      font-size: 21px;
    }
  }
}

@media screen and (max-width: 767px) {
  .documentTabs-wrapper {
    width: 100%;

    .documentTabs {
      display: block;
      margin: 0 0 40px;
      width: 100%;
      box-shadow: inset 0 -4px 5px -5px rgb(34 60 80 / 60%);
    }
    .documentTabs .tab-item__container {
      margin: 0;
    }
    .documentTabs {
      overflow-x: auto;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: -ms-autohiding-scrollbar;
    }

    .documentTabs::-webkit-scrollbar {
      display: none;
    }

    .documentTabs .tab-item__container {
      cursor: pointer;
      display: inline-block;
      color: #7a7a7a;
      text-align: center;
      text-decoration: none;
      font-weight: 500;
    }

    .documentTabs .tab-item__container:not(:first-child) {
      padding: 10px;
    }

    .documentTabs .tab-item__container:first-child {
      padding: 0 10px 0 0;
    }

    .documentTabs .tab-item__container:hover {
      color: #eb3d26;
    }
    .documentTabs .tab-item__container.active {
      color: #eb3d26;
      font-weight: 600;
    }
    .documentTabs .tab-item__container.active:after {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background-color: #eb3d26;
      margin-top: 2px;
    }
  }
}
