.GeneralInfo {
  .title-location {
    display: none;
    align-items: flex-start;
    justify-content: space-between;
    .main-btn.btn-form {
      margin-left: 0;
    }
  }
  .location {
    font-size: 1.7rem;
  }
  .form-group .control-label {
    max-width: 210px;
  }
  .created-by-employee__title {
    border-left: 2px solid red;
    padding-left: 10px;
    font-size: 14px;
    line-height: 26px;
    font-weight: 600;
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 767px) {
  .GeneralInfo {
    .title-location {
      display: flex;
      .additional-info-top__show-notes {
        margin-right: 10px;
      }
      .status-container__title {
        padding-left: 0;
        padding-right: 54px;
      }
      .additional-info-top__button {
        margin-top: 0.7rem;
      }
      .withStatusNote {
        .status-container__title {
          padding-right: 26px;
        }
      }
    }
  }
}

@media screen and (max-width: 650px) {
  .GeneralInfo {
    .title-location {
      display: flex;
      flex-direction: column;
      .location {
        padding-left: 15px;
      }
    }
  }
}

@media screen and (max-width: 380px) {
  .GeneralInfo {
    .title-location {
      .status-container {
        strong {
          padding-left: 0;
        }
        .status-container__select {
          margin-top: 8px;
        }
      }
    }
  }
}
