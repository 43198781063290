.small-textarea-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  .label-message {
    padding-top: 8px;
  }
  .tooltip-button {
    background-color: transparent;
    cursor: pointer;
    text-decoration: underline;
    display: inline;
    margin: 0;
    padding: 0;

    img {
      margin-bottom: 4px;
    }
  }
  .form-group .form-error-container {
    padding-left: 0;
    textarea {
      margin-left: 15px;
    }
  }
  .form-control {
    min-height: 38px;
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
  }
  textarea {
    resize: none;
  }

  .form-textarea {
    width: 100%;
    padding: 0;
    align-items: center;
    display: inline-block;
  }

  .error-textarea {
    font-size: 14px;
  }
}

@media (max-width: 767px) {
  .small-textarea-group {
    .form-textarea {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .textarea-group textarea {
      margin-left: 13px;
      padding-top: 0px;
      padding-left: 3px;
    }
    .form-control {
      vertical-align: top;
    }
  }

  .small-textarea-group > label {
    margin-bottom: 0px;
  }
}
