.feedback-form {
  position: relative;
}

.feedback-form__container {
  .form-title__container {
    display: flex;
    align-items: center;
  }
  .form-title {
    padding-left: 15px;
    max-width: 100%;
    &::after {
      content: '';
      display: block;
      width: 80px;
      height: 2px;
      background-color: #eb3d26;
      margin-top: 5px;
    }
  }
}
