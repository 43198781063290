.employee-profile {
  .additional-info-top {
    position: absolute;
    background-color: #fff;
    top: 16px;
    right: 0;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    .main-btn.btn-form {
      margin: 0;
    }
  }
}

@media screen and (max-width: 1200px) {
  .employee-profile .additional-info-top {
    align-items: flex-start;
    position: unset;
  }
}

@media screen and (max-width: 767px) {
  .employee-profile .additional-info-top {
    display: none;
  }
}
