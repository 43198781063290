.custom-accordion__title-container {
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  width: max-content;

  .accordionOpen {
    transform: rotateX(180deg) rotateY(0);
    transition: all 0.4s ease;
  }
  .accordionClose {
    transform: rotate(0);
    transition: all 0.4s ease;
  }
  .custom-accordion__header {
    display: flex;
    max-width: 100%;
    margin-bottom: 0;
  }

  .custom-accordion__icon {
    width: 24px;
  }
}