@media screen and (max-width: 767px) {
  .sigle-checkbox {
    display: flex;
    justify-content: space-between;
    .custom-checkbox.custom-control {
      margin-right: 0;
    }
  }
  .form-group.sigle-checkbox .control-label {
    max-width: 100%;
  }
}

.checkbox-reverse {
  .custom-checkbox .custom-checkbox__label span {
    margin-right: 0;
  }
}
