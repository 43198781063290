.RelativeRequestDetails {
  .relative-request-details__container {
    display: flex;
    min-height: calc(100vh - 190px);
    position: relative;
  }
  .relative-request-details__content {
    width: calc(100% - 230px);
    padding-left: 32px;
    border-left: 1px solid #c9c9c9;
    padding-top: 24px;
  }
}

.PetRequestDetails {
  .pet-request-details__container {
    display: flex;
    min-height: calc(100vh - 190px);
    position: relative;
  }
  .pet-request-details__content {
    width: calc(100% - 230px);
    padding-left: 32px;
    border-left: 1px solid #c9c9c9;
    padding-top: 30px;
  }
}

@media (max-width: 767px) {
  .RelativeRequestDetails {
    .relative-request-details__container {
      flex-direction: column;
      .relative-request-details__content {
        width: 100%;
        padding-left: 0;
        border-left: unset;
        padding-top: 16px;
      }
    }
  }
  .PetRequestDetails {
    .pet-request-details__container {
      flex-direction: column;
      .pet-request-details__content {
        width: 100%;
        padding-left: 0;
        border-left: unset;
        padding-top: 16px;
      }
    }
  }
}
