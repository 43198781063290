.employee-documents__container {
  .checkbox-wrap {
    display: flex;

    .withWhom-lable {
      margin: 15px 25px 0 0;
      font-weight: 500;
    }

    .final-checkbox-wrapper__block {
      padding-bottom: 0;
    }

    .withoutLable {
      .control-label {
        display: none;
      }
    }
  }
}

.accompanying__container {
  padding-bottom: 20px;
  .underlined {
    margin-bottom: 9px;
  }
}

@media (max-width: 992px) {
  .employee-documents__container {
    .checkbox-wrap {
      display: unset;
    }
  }
}
