.reminder-tab {
  .reminder-text {
    white-space: pre-line;
    word-break: break-word;
    font-size: 13px;
  }


  .ant-table-content {

    .ant-table-thead {
      .ant-table-cell {
        font-weight: 500;
        font-size: 13px;
        color: #7a7a7a;
        background: #ffffff;
        border-bottom: 1px solid #c9c9c9;
      }

      .ant-table-column-title {
        font-weight: 500;
        font-size: 13px;
      }
    }

    .ant-table-tbody {
      .ant-table-row {
        .ant-table-cell {
          font-size: 13px;
        }

        .ant-table-cell:nth-child(2) {
          font-weight: 600;
        }
      }
    }
  }

  .ant-table-wrapper .ant-table-pagination.ant-pagination {
    margin: 1rem 0 0 0;
  }
}