.display-attach-documents {
  .wrapper .exit-container {
    display: none;
  }
  .attach-document__title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    h4 {
      margin: 0;
    }
    img {
      cursor: pointer;
    }
  }
  .attach-document__content {
    min-height: 510px;
    padding-bottom: 5rem;
  }
  .wrapper .col-md-9 {
    flex: unset;
    max-width: 500px;
  }
  .attach-document__label {
    font-size: 16px;
    font-weight: 500;
    white-space: nowrap;
    flex: 0 0 40%;
    display: flex;
  }
  .attach-document__type-document {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
  }
  .select__type-document {
    width: 100%;
  }

  .wrapper .modal-container .legalization-document {
    .form-group {
      flex-wrap: wrap;
    }
    .attach-photos-dropzone.form-group {
      align-items: flex-start;
    }
  }

  .wrapper .modal-container .legalization-document .form-group {
    .error {
      padding-left: 40%;
    }
    label {
      max-width: 40%;
      text-align: start;
      padding: 0 16px 0 0;
    }
  }

  .wrapper .modal-container .legalization-document .form-group.date-field-notify {
    .control-label {
      max-width: 40%;
      text-align: start;
      padding: 0 16px 0 0;
      label {
        max-width: 100%;
      }
    }
  }
  .wrapper .modal-container .legalization-document .form-group .custom-checkbox__label {
    max-width: 100%;
  }
  .wrapper .modal-container .legalization-document .form-group .ant-datepicker-wrapper {
    max-width: 60%;
    flex: unset;
  }
  .wrapper .modal-container .legalization-document .form-group.date-field-notify .date-picker-wrapper {
    max-width: 60%;
    flex: unset;
    .ant-datepicker-wrapper {
      max-width: 100%;
    }
  }
  .wrapper .modal-container .legalization-document .form-group input {
    width: 100%;
  }
  .attach-documents__button-container {
    position: absolute;
    bottom: 40px;
    right: 40px;
    .main-btn.btn-form {
      margin: 10px 0 0 24px;
    }
    .spinner-btn {
      width: 96px;
    }
  }

  .notify-icon {
    background-size: unset;
    position: absolute;
    right: 4px;
    top: 8px;
  }
  .form-group {
    position: relative;
  }
  .form-group .responsible-select-top {
    min-width: 60%;
  }

  .checkboxes-vertical {
    flex: 0 0 60%;
    .ant-datepicker-wrapper {
      max-width: 100%;
    }
  }
  .checkbox-with-date-picker {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .checkbox-with-date-picker .custom_checkbox__limited-text {
    max-width: unset;
    min-width: unset;
    padding-left: 4px;
  }
  .wrapper .modal-container .form-group label {
    width: 100%;
  }
  .attachments-block {
    max-width: 100%;
  }
  .checkbox-with-date-picker {
    width: 100%;
  }
  .wrapper .modal-container .legalization-document .form-group.checkboxgroup__dates .ant-datepicker-wrapper {
    max-width: 100%;
  }
  .checkbox-group-with-date-picker .custom_checkbox__limited-text {
    word-break: unset;
  }
  .checkbox-group-with-date-picker {
    flex-wrap: wrap;
  }
}

@media (min-width: 768px) {
  .attach-document__content {
    .form-input-width {
      max-width: 60%;
    }
  }
}

@media (max-width: 767px) {
  .attach-document__content {
    .text-field__wrapper {
      width: 100%;
    }
    .control-label {
      padding-left: 15px;
    }
  }
  .display-attach-documents
    .wrapper
    .modal-container
    .legalization-document
    .form-group.date-field-notify
    .date-picker-wrapper {
    max-width: 100%;
  }
}

@media (max-width: 857px) {
  .display-attach-documents {
    .attach-documents__button-container {
      right: 1rem;
    }
  }
}

@media (max-width: 767px) {
  .display-attach-documents .wrapper .modal-container .legalization-document .form-group label {
    max-width: 100%;
  }
  .display-attach-documents {
    .attach-document__type-document {
      flex-wrap: wrap;
      margin-bottom: 20px;
      .select__type-document {
        margin-top: 4px;
      }
      .attach-document__label {
        padding-bottom: 8px;
      }
    }
    .form-group .form-control {
      margin-left: 0;
      margin-top: 4px;
    }
    .form-group .responsible-select-top {
      min-width: 100%;
      margin-left: 0;
    }
    .wrapper .modal-container .legalization-document .form-group {
      margin-bottom: 20px;
      flex-direction: column;
      align-items: flex-start;
      .error {
        padding-left: 0;
      }
    }
    .wrapper .modal-container .legalization-document .form-group .ant-datepicker-wrapper {
      max-width: 100%;
    }
    .notify-icon {
      top: 32px;
    }
    .form-group .checkboxes-vertical {
      padding-left: 0;
      margin-top: 4px;
    }
  }
}
