@import '../../../../../assets/css/variables.scss';

.multiple-filter__checkbox-tree {
  .ant-tree {
    .ant-tree-list-holder-inner {
      max-height: 264px;
      overflow-y: auto;

      .ant-tree-treenode {
        width: 100%;
        align-items: center;

        &:hover {
          background-color: rgba(0, 0, 0, 0.04);
        }
      }
    }

    .ant-tree-checkbox + span {
      align-items: center;

      &:hover {
        background-color: unset;
      }
    }

    .ant-tree-checkbox {
      margin-block-start: 0;
    }

    .ant-tree-title {
      font-size: 14px;
    }
  }

  .ant-tree-switcher {
    svg {
      fill: #555555;
      font-size: 13px;
    }

    .ant-tree-switcher-icon {
      display: inline-flex;
    }
  }

  .ant-tree .ant-tree-treenode {
    padding: 5px 12px 5px 0;
  }
}
