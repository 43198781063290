.legalization-request-details__wrapper {
  .legalization-request-details {
    max-width: 720px;
    margin-bottom: 1rem;

    .question-block {
      .responsible-select-top {
        min-width: 400px;
        max-width: 400px;
      }

      .form-group {
        .control-label {
          max-width: unset;
          padding-right: 40px;
        }
      }
    }
  }
  .status-timeline {
    width: 100%;
    max-width: 400px;
  }
  .contact-employee-sent-by-post a:hover {
    text-decoration: none;
    border-bottom: none;
  }
}