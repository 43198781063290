.reminders-column {
  width: max-content;
  max-width: 250px;
  margin: 0;
  &__author {
    font-weight: 500;
  }

  &__item {
    font-size: 14px;
  }

  &__item:not(:last-child) {
    border-bottom: 1px solid #ced4da;
    margin-bottom: 2px;
    padding-bottom: 2px;
  }
}
