.phone-field-error {
  color: red;
  margin: 10px 0 0 15px;
}

.phone-field {
  display: flex;
  flex-wrap: wrap;
  .phone-block {
    display: flex;
    width: 300px;
    .phone-field__container {
      max-width: 300px;
      width: 100%;
    }
  }
  .control-label {
    padding-top: 10px;
  }

  .btn:focus {
    border-color: #f69799;
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgb(235 34 39 / 25%);
  }
}

.phone-field {
  .field-array {
    display: inline-block;
    padding: 0;
    // width: 100%;
  }
  .phone-block:not(:last-child) {
    .form-control {
      margin-bottom: 1rem;
    }
  }
}

@media screen and (max-width: 767px) {
  .phone-field .phone-block .phone-field__container {
    max-width: unset;
  }
  .phone-field-error {
    margin: 0 0 1rem 0;
  }
  .phone-field {
    display: flex;
    flex-direction: column;
    .phone-block {
      width: 100%;
      .form-control {
        margin-bottom: 8px;
      }
    }
    .phone-block:not(:last-child) .form-control {
      margin-bottom: 8px;
    }
    .field-array {
      .phone-block {
        flex-direction: column;
        .field-error {
          padding-left: 15px;
        }
      }
    }
  }
}

@media screen and (max-width: 480px) {
  .field-array {
    padding: 0 15px;
  }
}
