@import '../../../assets/css/variables.scss';

.document {
  position: relative;
  .title-container {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .document-form__accordion {
      cursor: pointer;
      padding-left: 10px;
      margin-bottom: 5px;
    }
    .accordionOpen {
      transform: rotateX(180deg) rotateY(0);
      transition: all 0.4s ease;
    }
    .accordionClose {
      transform: rotate(0);
      transition: all 0.4s ease;
    }
    .Document__title {
      padding-left: 15px;
      max-width: 100%;
      margin-bottom: 0;
      &::after {
        content: '';
        display: block;
        width: 80px;
        height: 2px;
        background-color: #eb3d26;
        margin-top: 5px;
      }
    }

    .icon {
      height: 1.8rem;
      margin-bottom: 30px;
    }
  }
  .icon {
    cursor: pointer;
  }
  .document-container:not(:last-child):after {
    content: '';
    display: block;
    width: 135px;
    height: 3px;
    background-color: #ff0000;
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .button-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .label-message {
    // flex: 0 0 40%;
    // max-width: 40%;
    .error-textarea {
      padding-left: 1rem;
    }
  }
  .document-container {
    .document-form {
      position: relative;
      &-buttons {
        position: absolute;
        right: 40%;
        top: 0;
        display: flex;
      }
    }
    .description {
      padding-left: 0;
      margin-bottom: 30px;
      max-width: 80%;
      a {
        font-weight: 700;
        text-decoration: underline;
      }
      a:hover {
        color: inherit;
      }
    }
  }
  .hidden {
    display: none;
  }
  .attachments-block {
    display: flex;
    flex-wrap: wrap;
    max-width: 50%;
    margin-top: 15px;
  }
}

@media (max-width: 992px) {
  .document {
    .document-container {
      .document-form {
        &-buttons {
          right: 30%;
        }

        .textarea-group {
          .form-error-container {
            padding-left: 0;
          }
        }
      }
    }
  }
  .document .document-container .description {
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  .document {
    .document-container {
      .document-form {
        &-buttons {
          left: unset;
          right: 0;
        }
        .form-group {
          // display: block;

          .ant-datepicker-wrapper {
            width: 100%;
          }
          .notify-icon {
            position: absolute;
            right: 3rem;
            margin-top: -2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 530px) {
  .document {
    .document-container {
      .document-form {
        .form-group {
          .notify-icon {
            right: 2rem;
          }
        }
      }
    }
  }
}

@media (max-width: 375px) {
  .document {
    .document-container {
      .document-form {
        .form-group {
          .notify-icon {
            right: 1rem;
          }
        }
      }
    }
  }
}

@media (max-width: 767px) {
  .document {
    .attachments-block {
      max-width: 100%;
    }

    .document-container {
      .document-form {
        &-buttons {
          right: 0.6rem;
          top: -1.3rem;
        }
        .archive-icon {
          height: 1.5rem;
        }
        .delete-icon {
          height: 1.5rem;
        }
      }
    }
    .attachments-block {
      .add-attachment {
        margin: 0 0 10px 0;
        height: 1.8rem;
        cursor: pointer;
      }
    }
    .Document__title,
    .title-container .icon {
      margin-bottom: 1rem;
      padding-left: 0;
    }
    .main-btn.btn-form {
      margin: 1.5rem 1rem 10px 0;
    }
  }
}

@media (max-width: 576px) {
  .document {
    .edit-btn {
      display: none;
    }
  }
}
